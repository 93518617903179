@use "./../../variables/variables.scss" as var;

.textarea {
  border-radius: 10px;
  padding: 0.5rem;
  width: 100%;
  padding-left: 1rem;
  resize: vertical;
  background-color: var.$color-input-bg;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: var.$color-text-white;
  line-height: 1.4;
  // font-size: 1.2rem;
  //   overflow: hidden;
  //   overflow-y: scroll;
  //   scrollbar-width: thin;
  // background-color: rgb(67, 51, 75);
  // color: #fff;

  &--error {
    border: 1px solid rgba(var.$color-red, 0.6);
    // border: 1px solid var.$color-text-red;
  }
  @include var.custom-scroll // &::-webkit-scrollbar {
    //   width: 8px; /* width of the entire scrollbar */
    //   border-radius: 1rem;
    // }
    // &::-webkit-scrollbar-track {
    //   background: #443d62; /* color of the tracking area */
    //   border-radius: 1rem;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: #ababab; /* color of the scroll thumb */
    //   border-radius: 20px; /* roundness of the scroll thumb */
    //   //   border: 3px solid #ababab; /* creates padding around scroll thumb */
    //   &:hover {
    //     background-color: #818181; /* color of the scroll thumb */
    //   }
    // }
;
}
.label {
  display: inline-block;
  font-size: 1.6rem;
  margin: 0 0.6rem 0.4rem;
}

.error {
  font-size: 1.4rem;
  color: var.$color-text-red;
  margin: 0.2rem 1.8rem 0rem;
}
