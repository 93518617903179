@use "./../../../../variables/variables.scss" as var;
@include var.animation-show;

.guide__content {
  &--default {
    top: 3.5rem;
    right: 1rem;
  }

  &--mode {
    top: -0.1rem;
    left: 18rem;
  }

  &--0 {
    top: -0.1rem;
    left: 18rem;
  }

  &--1 {
    top: -0.1rem;
    left: 25rem;
  }

  &--2 {
    top: 0rem;
    left: 35rem;
  }
  &--3 {
    top: -4rem;
    left: 29rem;
  }

  &--4 {
    top: -3.8rem;
    left: 32rem;
  }

  &--5 {
    top: 4rem;
    right: 3.6rem;
  }

  &--6 {
    top: 6.4rem;
    right: 6.6rem;
  }
}

.svg {
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(2px);
}

@media (max-width: 1025px) {
  .guide__content {
    &--default {
      top: 3.5rem;
      right: 50%;
      transform: translateX(50%);
      z-index: 20;
    }
  }
}

@media (max-width: 540px) {
  .guide__content {
    &--1 {
      left: 22rem;
    }
  }
}

@media (max-width: 460px) {
  .guide__content {
    &--0 {
      left: 14rem;
    }
    &--1 {
      left: 21rem;
    }
    &--3 {
      top: 10rem;
    }
  }
}
