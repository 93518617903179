@use "../../variables/variables.scss" as var;

.error-page {
  height: 100vh;
  padding: 2.8rem;
  margin: auto;
  &__title {
    font-size: 12rem;
  }

  &__subtitle {
    font-size: 2.8rem;
  }

  &__message {
    font-size: 1.8rem;
  }

  &__link {
    text-decoration: none;
    font-size: 2.8rem;
    color: lighten(var.$color-primary, 15%);
    font-weight: 600;
    font-family: inherit;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: lighten(var.$color-primary, 25%);
    }

    &:disabled {
      color: var.$color-gray;
    }
  }
}

.error-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
}
