@use "./../../variables/variables.scss" as var;

.modal {
  position: fixed;
  animation: show 0.3s ease;

  &--backdrop {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }

  &--content {
    top: 50%;
    left: 50%;
    // height: auto;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: 40;
    max-width: 90vw;
    min-width: 20rem;
    max-height: 95vh;
    // overflow-y: scroll;
    // @include var.custom-scroll;
  }

  &__close {
    position: fixed;
    cursor: pointer;
    top: 0.6rem;
    right: 0.6rem;
    color: #a8a8a8;
    transition: all 0.3s;
    z-index: 50;

    & svg {
      width: 3.4rem;
    }

    &:hover {
      color: lighten(#a8a8a8, 20%);
    }
  }

  &__cross {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(168, 168, 168);
    clip-path: polygon(
      10% 0,
      0 10%,
      40% 50%,
      0 90%,
      10% 100%,
      50% 60%,
      90% 100%,
      100% 90%,
      60% 50%,
      100% 10%,
      90% 0,
      50% 40%
    );
  }
}

// .test {
//   position: relative;
//   // z-index: 20;
// }

.title {
  margin-bottom: 1rem;
}

.img {
  display: flex;
  // width: 12rem;
  height: 100%;
  min-height: 10rem;
  max-height: 95vh;
  max-width: 90vw;
  // min-width: 16rem;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #383838;
  //   cursor: pointer;
  box-shadow: var.$box-shadow;
  overflow: hidden;

  & img {
    position: absolute;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
    // transition: all 0.3s;
  }

  &--hidden {
    opacity: 0;
  }
}

.btn-slide {
  position: fixed;
  width: 4rem;
  height: 4rem;
  top: 50%;
  z-index: 50;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: var.$color-primary;
  }

  &--next {
    left: 1rem;
  }
  &--prev {
    right: 1rem;
  }
}

.spiner-container {
  position: absolute;
  height: 5rem;
  z-index: 60;
  top: 50%;
  left: 50%;
  // transform: translate(-50% -50%);
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
