@use "../../variables/variables.scss" as var;

.profile {
  &__container {
    display: flex;
    gap: 2rem;
  }

  &__img {
    & svg {
      width: 100%;
      height: 16rem;
    }
  }

  &__title {
    font-family: var.$font-family-secondary;
    font-size: 3.2rem;
    margin-bottom: 2.4rem;
    font-weight: 500;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
  }

  &__form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__field {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }

  &__pass-field {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__element {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    gap: 1rem;
    margin-left: 1rem;
  }
}

.btn {
  margin-top: 0.4rem;
}

.link {
  @include var.linkA;
}

@media (max-width: 700px) {
  .profile {
    &__container {
      flex-direction: column;
    }
  }
}

@media (max-width: 700px) {
  .profile {
    &__element {
      font-size: 1.8rem;
    }
  }
}
