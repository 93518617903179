@use "./../../../variables/variables.scss" as var;

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  position: relative;
  animation: show 0.3s ease-in forwards;
}

.subtitle {
  position: absolute;
  font-size: 1.4rem;
  top: 0;
  right: 0;
  color: #818182;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.filter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.example-field {
  display: flex;
  gap: 1rem;
}

.tagset {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-size: 1.6rem;
  }
}

.btn-secondary {
  font-size: 1.6rem;
  color: #818182 !important;
  text-align: right;
}

.checkbox {
  display: flex;
  font-size: 1.6rem;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
}

.versions {
  // height: 100%;
}

.submit-container {
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  bottom: 0.4rem;
}

.submit {
  display: flex;
  // position: sticky;

  // height: 3.2rem;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  min-height: 3.4rem;
  width: 100%;
  // width: 12rem;
  // align-self: flex-end;
}

.weight {
  display: flex;
  gap: 1rem;
  // align-items: center;
  // gap: 0.3rem;
  &__label {
    display: inline-block;
    font-size: 1.6rem;
    margin: 0 0.6rem 0.4rem;
  }
}

.input__btn-del {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0.2rem;
  flex-shrink: 0;

  & svg {
    color: var.$color-gray;
    width: 1.8rem;
    height: 1.8rem;
  }
}

@include var.animation-show;
