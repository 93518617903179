@use "./../../variables/variables.scss" as var;

.presets-container {
  display: flex;
  gap: 2rem;
  min-width: 30rem;
}

.presets {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // background-color: var.$color-background-tertiary;
  padding: 1rem;
  // border-radius: 10px;

  overflow-y: scroll;
  // max-height: 40vh;
  max-height: 34vh;
  @include var.custom-scroll;

  &__name {
    font-size: 1.8rem;
  }

  &__bg {
    display: flex;
    background-color: var.$color-background-tertiary;
    padding: 0.2rem;
    border-radius: 10px;
    margin-top: 1rem;
    // min-height: 27rem;
    width: 100%;
    overflow: hidden;
  }
}

.preset {
  display: flex;
  gap: 1rem;
  min-width: 25rem;
  justify-content: space-between;
  align-items: center;

  // align-items: center;
  //   width: 60rem;
  // grid-template-columns: 16rem 1fr;

  &__name {
    display: inline-block;
    padding: 0.2rem 0.8rem;
    border-radius: 10px;
    width: 100%;
    transition: background-color 0.3s;
    cursor: pointer;
    @include var.ellipsis(2);
    border: 1px solid lighten(var.$color-background-tertiary, 20%);
    text-align: center;

    &:hover {
      background-color: lighten(var.$color-background-tertiary, 20%);
    }
  }

  &__btns-container {
    display: flex;
    gap: 0.6rem;
  }
}

.btn-del {
  background-color: var.$color-background-red;

  &:hover {
    background-color: lighten(var.$color-background-red, 10%);
  }
}

.btn-from {
  position: absolute;
  top: 2.4rem;
  right: 4rem;
}

.modal {
  position: fixed;
  animation: show 0.3s ease;
  box-shadow: var.$box-shadow;

  &--backdrop {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }

  &--content {
    top: 50%;
    left: 50%;
    // height: auto;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: 30;
    max-width: 90vw;
    min-width: 40rem;
    max-height: 95vh;
    // overflow-y: scroll;
    // @include var.custom-scroll;
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 0.8rem;
    right: 0.8rem;
    color: #a8a8a8;
    transition: all 0.3s;

    & svg {
      width: 2.4rem;
    }

    &:hover {
      color: lighten(#a8a8a8, 20%);
    }
  }

  &__cross {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(168, 168, 168);
    clip-path: polygon(
      10% 0,
      0 10%,
      40% 50%,
      0 90%,
      10% 100%,
      50% 60%,
      90% 100%,
      100% 90%,
      60% 50%,
      100% 10%,
      90% 0,
      50% 40%
    );
  }
}

.test {
  position: relative;
  z-index: 20;
}

.title {
  margin-bottom: 1rem;
}

.btn-back {
  position: absolute;
  top: 0.6rem;
  left: 1.4rem;
  // background-color: transparent;
  background: none;
  border: none;
  color: var.$color-text-gray;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: lighten(var.$color-text-gray, 20%);
  }

  & svg {
    width: 2rem;
    height: 2rem;
  }
}

.notification {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  // margin-bottom: 3rem;
  background-color: var.$color-background-tertiary;
  border-radius: 10px;
  padding: 1rem 0.9rem;

  &__text {
    color: var.$color-text-gray;
  }

  &__svg {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    color: var.$color-text-gray;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 840px) {
  .presets-container {
    flex-wrap: wrap;
  }
}
