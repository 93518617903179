@use "./../../variables/variables.scss" as var;

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-direction: column;
  gap: 1rem;
}

.wrap {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2rem;
}

.active {
  color: var.$color-primary;
  border-color: var.$color-primary;
}

.config {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 2rem;
}

.tag-menu {
  position: relative;
  width: 100%;
  // padding-right: 2rem;
  font-size: 1.8rem;
  // border-right: 1px solid #fff;
}

.tag-menu__labels {
  // display: flex;
  // align-items: center;
  gap: 2rem;
  // margin-bottom: 0.8rem;
  // list-style: none;
  // flex-wrap: wrap;
  // cursor: pointer;
}

.category__link {
  position: relative;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;

  &:not(.active):hover {
    // border-bottom: 1px solid var.$color-primary;
    // color: #b261f5;
    color: lighten(var.$color-primary, 20%);
  }

  &--all {
    // padding-right: 2rem;
    // border-right: 1px solid var.$color-text-gray-dark;
    border: 1px solid var.$color-violet-grey;
    border-radius: 10px;
    padding: 0.2rem 1.3rem;
    font-size: 1.6rem;
    transition: all 0.3s ease;

    &:not(.active):hover {
      border: 1px solid lighten(var.$color-primary, 20%);
    }

    &.active {
      border-color: var.$color-primary;
    }
  }
}

.tip {
  //   display: flex;
  //   align-items: center;
  gap: 4rem;
  width: 100%;
  //   justify-content: center;
  flex-grow: 1;
  //   font-size: 1.6rem;
  margin: 1rem auto;
  margin-bottom: 2rem;
  padding: 2rem 2rem;
  border-radius: 10px;
  background-color: rgba(var.$color-background-tertiary, 0.7);
  background-color: #1f1931;
  background: linear-gradient(to top left, #1f1931, #5a3ab2);
  background: linear-gradient(to top left, #1f1931, #382a5f);
  background: linear-gradient(to bottom right, #1f1931, #382a5f);
  background: linear-gradient(to bottom right, #1f1931, #291f44);
  background: linear-gradient(
    to bottom right,
    var.$color-background-tertiary,
    #291f44
  );
  background: linear-gradient(
    to bottom right,
    var.$color-background-secondary,
    #232835
  );
  //   background: linear-gradient(
  //     to bottom right,
  //     var.$color-background-secondary,
  //     #262335
  //   );
  // background: linear-gradient(to top left, #1f1931, #3e255a);
  // background-color: rgba(var.$color-background-tertiary, 1);
  box-shadow: var.$box-shadow;
  box-shadow: var.$box-shadow-inset;

  &__title {
    font-size: 2.8rem;
    padding-left: 1rem;
    border-left: 5px solid var.$color-primary;
    margin-bottom: 2.8rem;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 4rem;
    margin-top: 2rem;
    font-size: 1.6rem;

    &__text {
      font-size: 1.6rem;
      // margin-bottom: 1.6rem;
    }

    &__list {
      display: flex;
      flex-direction: column;
      list-style: none;
      font-size: 1.6rem;
      gap: 3.8rem;
    }

    &__item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      gap: 1rem;
      font-size: 1.6rem;
      // margin-bottom: 3.4rem;

      & svg {
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__img-wrap {
    width: 100%;
    // min-width: 30rem;
    flex-shrink: 0;
  }

  &__img {
    width: 100%;
    box-shadow: var.$box-shadow;
  }
}

.guide-intro {
  display: block;
}

@media (max-width: 600px) {
  .guide {
    display: none;
  }
}

@media (max-width: 500px) {
  .guide-intro {
    display: none;
  }
}
