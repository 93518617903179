@use "./../../../variables/variables.scss" as var;

.images-list__label {
  display: block;
  height: 14rem;
}

.checkbox {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}

.image {
  cursor: pointer;
  transition: all 0.3s ease;

  & img {
    position: relative;
  }

  &--active {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 123, 200, 0.6);
      z-index: 1;
    }
  }

  &--saved {
    cursor: default;
    &::before {
      background-color: rgba(0, 200, 190, 0.6);
      background-color: rgba(184, 184, 184, 0.85);
    }
  }
  &--del {
    &::before {
      background-color: rgba(200, 20, 0, 0.6);
    }
  }
}
