@use "../../variables/variables.scss" as var;

.field {
  // display: flex;
  // flex-wrap: wrap;
  // gap: 0.7rem;
  list-style: none;
  padding: 0.6rem 0.8rem;
  border: 1px solid var.$color-violet-grey;
  border-radius: 1rem 0rem 0rem 1rem;
  //   margin: 1rem 0 1rem;
  align-items: flex-start;
  overflow-y: scroll;
  // background-color: var.$color-background-tertiary;
  word-spacing: 0.4rem;
  letter-spacing: 0.1px;
  width: 100%;
  height: 100%;
  font-size: 0;
  // resize: vertical;
  // &::-webkit-resizer {
  //   border: 2px solid yellow;
  //   background: blue;
  //   box-shadow: 0 0 2px 5px red;
  //   outline: 2px dashed green;
  //   /*size does not work*/
  //   display: block;
  //   width: 150px !important;
  //   height: 150px !important;
  // }
  @include var.custom-scroll;

  // &--positive {
  //   max-height: calc(100vh - 300px);
  //   height: 10rem;
  //   min-height: 10rem;
  // }
  // &--negative {
  //   max-height: 180px;
  //   height: 6rem;
  //   min-height: 6rem;
  // }
}

.tag {
  display: inline-block;
  // margin: 0 1rem 1rem 0;
  font-size: 1.4rem;
  // padding: 0.4rem 0.6rem;
  background-color: #323148;
  border-radius: 1rem;
  overflow: hidden;
  // transition: all 0.3s;
  cursor: grab;

  &--duplicate-1 {
    outline: solid 1px rgb(225, 229, 3);
  }
  &--duplicate-2 {
    outline: solid 1px rgb(154, 229, 3);
  }
  &--duplicate-3 {
    outline: solid 1px rgb(37, 229, 3);
  }
  &--duplicate-4 {
    outline: solid 1px rgb(0, 179, 131);
  }
  &--duplicate-5 {
    outline: solid 1px rgb(3, 214, 229);
  }
  &--duplicate-6 {
    outline: solid 1px rgb(35, 108, 216);
  }
  &--duplicate-7 {
    outline: solid 1px rgb(44, 3, 229);
  }
  &--duplicate-8 {
    outline: solid 1px rgb(169, 3, 229);
  }
  &--duplicate-9 {
    outline: solid 1px rgb(229, 3, 206);
  }
  &--duplicate-10 {
    outline: solid 1px rgb(175, 0, 111);
  }
  &--duplicate-11 {
    outline: solid 1px rgb(229, 3, 97);
  }
  &--duplicate-12 {
    outline: solid 1px rgb(229, 93, 3);
  }

  &--break {
    background-color: var.$color-background-dark;
  }

  &__content {
    display: flex;
    // align-items: center;
    gap: 0.2rem;
    // display: grid;
    // // grid-template-columns: 1fr auto;
    // grid-template-columns: 1fr auto auto;
    // gap: 0.6rem;
  }
  &__text {
    // margin-right: 0.6rem;
    padding: 0.4rem 0.6rem;
    word-break: break-word;
    // cursor: default;

    &--edit {
      // padding: 0.4rem 1rem;
      // background-color: var.$color-background-primary;
      // border-radius: 8px;
      border-radius: 10px;
      padding: 0.4rem 1rem;
      width: 100%;
      padding-left: 1rem;
      background-color: var.$color-input-bg;
      border: 1px solid rgba(255, 255, 255, 0.3);
      box-shadow: var.$box-shadow-inset;
      color: var.$color-text-white;
      color: var.$color-text-gray;
    }
  }

  &__weight {
    width: 4rem;
  }

  &__cross {
    display: inline-block;
    width: 1.4rem;
    height: 1.6rem;
    // background-color: var.$color-violet-grey;
    color: var.$color-violet-grey;
    transition: all 0.3s ease;
    // clip-path: polygon(
    //   10% 0,
    //   0 10%,
    //   40% 50%,
    //   0 90%,
    //   10% 100%,
    //   50% 60%,
    //   90% 100%,
    //   100% 90%,
    //   60% 50%,
    //   100% 10%,
    //   90% 0,
    //   50% 40%
    // );

    & svg {
      width: 1.6rem;
    }

    &--submit {
      margin: 0 1rem;
      color: var.$color-white;
    }

    &:hover {
      color: var.$color-white;
    }
  }

  &__dragged {
    opacity: 0.3;
    // background-color: #52515f;
    // border: 1px solid #52515f;
  }
}

.btn {
  display: flex;
  align-items: center;
  // height: 100%;
  padding: 0.4rem 0.6rem;
  color: var.$color-violet-grey;
  background: none;
  border: none;
  // border-left: 1px solid var.$color-violet-grey;
  padding-left: 0.3rem;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 70%;
    width: 1px;
    background-color: var.$color-violet-grey;
    left: 0;
  }

  &:hover {
    color: var.$color-white;
  }

  &--submit {
    background-color: var.$color-primary;
    // color: var.$color-green;
    &:hover {
      background-color: darken(var.$color-primary, 5%);
    }

    &:active {
      box-shadow: inset 0.2rem 0.3rem 0.5rem rgba(var.$color-black, 0.4);
    }

    &:disabled {
      background-color: var.$color-gray;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}

.placeholder {
  font-size: 1.4rem;
  color: var.$color-text-gray-dark;
  color: #777777;
  user-select: none;
  cursor: default;
  //   word-spacing: 0.3rem;
  //   letter-spacing: 0.4px;

  &--aditional {
    display: flex;
    justify-content: center;
    margin: 0.5rem auto;
    max-width: 40rem;
    text-align: center;
  }
}

.tag-wrap {
  display: inline;
}

.tag-container {
  display: inline-block;
  // margin: 0 1rem 1rem 0;
  // padding: 0 1rem 1rem 0;
  padding: 0.5rem;
  border: 2px solid transparent;

  // transition: all 0.3s;
  // background-color: blueviolet;
}

.drop-left {
  // background-color: aqua;
  // padding-left: 8rem;
  // padding: 0.5rem 0 0.5rem 1rem;
  border-left-color: #e7008b;
  // border-left: 1px solid #fff;
  // margin-left: 3rem;
}
.drop-right {
  // background-color: blueviolet;
  // padding-right: 8rem;
  // padding: 0.5rem 1 0.5rem 0rem;
  border-right-color: #e7008b;
  // border-right: 1px solid #fff;
  // margin-right: 3rem;
}

.activation-tag {
  display: flex;
  gap: 0.3rem;
  align-items: center;

  &__btn-container {
    display: flex;
    gap: 0.1rem;
    flex-direction: column;
    width: 2rem;
    flex-shrink: 0;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    background-color: #323148;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: lighten(#323148, 5%);
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
    &:active {
      border: 1px solid rgba(255, 255, 255, 0.3);
      background-color: darken(#323148, 5%);
      box-shadow: var.$box-shadow-inset;
    }
    &-arrow {
      display: inline-block;
      background-color: #636363;
      height: 1.2rem;
      width: 1.2rem;

      &--up {
        clip-path: var.$clip-path-arrow-up;
      }
      &--down {
        clip-path: var.$clip-path-arrow-down;
      }
    }
  }
}

.edit {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.divider {
  opacity: 0.2;
  margin-bottom: 0.8rem;
  border: 0;
  border-top: 1px solid var.$color-gray;
}

@media (max-width: 480px) {
  .placeholder {
    &--aditional {
      margin: 2.5rem auto;
    }
  }
}
