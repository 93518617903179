@use "../../../variables/variables.scss" as var;

.info {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  background-color: var.$color-background-secondary;
  box-shadow: var.$box-shadow;
  gap: 1.4rem;
  width: 20rem;
  flex-shrink: 0;
  word-break: break-word;

  &__name {
    color: var.$color-text-gray;
  }
}

.type {
  //   position: absolute;
  //   top: 0.8rem;
  //   left: 0.8rem;
  background-color: rgba(137, 43, 226, 0.7);
  padding: 0.4rem 0.8rem;
  border-radius: 1rem;
  text-transform: uppercase;
  align-self: self-start;
  //   font-size: 1.6rem;
  //   z-index: 2;
}

.model {
  // display: flex;
  // align-items: center;
  color: var.$color-text-gray;
  background-color: #323148;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  font-size: 1.4rem;
}

@media (max-width: 1000px) {
  .info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    width: 100%;
    justify-items: flex-start;
  }
}

@media (max-width: 640px) {
  .info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 100%;
    justify-items: flex-start;
  }
}

@media (max-width: 425px) {
  .info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    justify-items: flex-start;
  }
}
