@use "./../../variables/variables.scss" as var;

.notification-container {
  position: fixed;
  bottom: 2%;
  left: 50%;
  width: 90%;
  max-width: 90rem;
  z-index: 999;
}

.notification {
  display: grid;
  height: auto;
  grid-template-columns: auto 1fr auto;
  background-color: #28273a;
  box-shadow: 0rem 1rem 3rem rgba(0, 0, 0, 0.2);
  padding: 1.6rem;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  // animation: slideIn 0.1s ease-out;
  z-index: 100;

  &__icon {
    color: var.$color-primary;
    width: 4.6rem;
    height: 4.6rem;
  }

  &__message {
    // font-size: 2rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 1.6rem;
  }
}

@media (max-width: 510px) {
  .notification {
    grid-template-columns: 1fr;

    &__btn {
      width: 100%;
    }

    &__icon {
      display: none;
    }
  }
}

@media (max-width: 440px) {
  .notification {
    // grid-template-columns: 1fr;

    &__btn {
      width: 100%;
    }

    &__icon {
      display: none;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translate(-50%, 50%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
}
