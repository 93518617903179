@use "../../../variables/variables.scss" as var;

.wrap {
  position: relative;
  display: flex;
  gap: 2rem;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
  //   justify-content: space-between;
  //   padding: 1rem;
}

.btn-menu {
  display: none;
  position: fixed;
  top: 7rem;
  left: 0rem;
  // height: 2rem;
  z-index: 3;
  border-radius: 0 10px 10px 0;
  border-left: transparent;
  padding: 1rem;
}

.menu {
  // position: sticky;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 1rem;
  width: 18rem;
  list-style: none;
  font-size: 1.8rem;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  // transition: all 0.3s ease;
  // background-color: var.$color-background-tertiary;

  // padding: 1rem;
}

.menu-item {
  cursor: pointer;
  margin-bottom: 1rem;
  line-height: 1.2;
  transition: all 0.3s ease;
  transition-property: color, background-color, padding, border-radius;

  &--active {
    color: blueviolet;
    background-color: #201f2f;
    padding: 0.3rem 1rem;
    border-radius: 1rem 0 0 1rem;
  }
}

.content {
  position: relative;
  width: 100%;
  padding: 1rem;
  // animation: show 0.4s ease-in forwards;
}

@include var.animation-show;

.versions {
  font-size: 1.6rem;
  list-style: none;
  padding-left: 2rem;
  // & li {
  //   margin-bottom: 1rem;
  //   line-height: 1.2;
  // }
}

.update {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &--guide {
    @include var.pulse;
  }
}

.btn-update {
  width: 14rem;
  height: 2.9rem;
  position: relative;
}

.btn-del {
  background-color: var.$color-background-red;

  &:hover {
    background-color: darken(var.$color-red, 10%);
  }
}

// .del-request {
//   width: 36rem;

//   &__message {
//     font-size: 1.6rem;
//     text-align: center;
//   }

//   &__btn-container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 2rem;
//     margin-top: 2rem;
//   }
// }
.menu-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  // background-color: #2218a8;
  z-index: 1;
}

@media (max-width: 580px) {
  .btn-menu {
    display: block;
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(var.$color-background-tertiary, 0.9);
    height: calc(100vh - 5rem);
    margin-top: 5rem;
    padding: 6rem 1.6rem 0;
    width: 30rem;
    max-width: 70%;
    gap: 1rem;
    overflow-y: scroll;
    @include var.custom-scroll;
    z-index: 2;

    &--hidden {
      transform: translateX(-100%);
    }
  }

  .content {
    margin-top: 3rem;
    // margin-left: 3rem;
    padding-left: 1rem;
  }
}
