@use "../../variables/variables.scss" as var;

.model {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 2rem;
  // font-size: 2.2rem;
  width: 100%;
  color: var.$color-text-white;
  // overflow: hidden;
  // animation: show 0.4s ease-in forwards;
  animation: show 0.6s ease-in forwards;
}

.h2 {
  font-size: 2.4rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.35;
}

.btn-add {
  display: inline-block;
  color: var.$color-text-white;
  background-color: rgb(var.$color-background-tertiary, 0.8);
  font-size: 2.8rem;
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  flex-shrink: 0;
  cursor: pointer;

  &:hover {
    background-color: lighten(rgb(var.$color-background-tertiary, 0.8), 4%);
  }
}

.hashtags {
  display: flex;
  align-items: center;
  gap: 1rem;
  list-style: none;
  flex-wrap: wrap;

  &__tag {
    font-size: 1.4rem;
    color: var.$color-text-gray;
    padding: 0.4rem 1rem;
    border-radius: 10px;
    background-color: rgb(var.$color-background-tertiary, 0.6);
    box-shadow: var.$box-shadow-light;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      // background-color: lighten(#323148, 5%);
      // background-color: #5f3d62;
      border: 1px solid rgba(255, 255, 255, 0.4);
    }

    &:active {
      border: 1px solid rgba(255, 255, 255, 0.3);
      background-color: darken(#323148, 5%);
      box-shadow: var.$box-shadow-inset;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 1.4rem;
    color: #acacac;
    border-radius: 10px;
    border: 1px solid transparent;
    background: none;
    transition: border 0.3s;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }

    &--show {
      padding: 0.4rem 0.4rem 0.4rem 1rem;
    }

    &--hide {
      padding: 0.4rem 1rem 0.4rem 0.4rem;
    }
  }

  &__btn-icon {
    width: 1.4rem;
    height: 1.4rem;
  }
}

// .panel {
//   // display: flex;
//   // justify-content: space-between;
//   display: grid;
//   gap: 1.6rem;
//   grid-template-columns: 8rem 1fr 8rem;
// }

.subcategories {
  display: flex;

  gap: 2rem;
  list-style: none;
  border-left: 1px solid #fff;
  padding-left: 1rem;
}

.title-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-container {
  position: relative;
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 40rem;
  width: 100%;

  &--guide {
    @include var.pulse;
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.4rem;
    // background-color: var.$color-background-tertiary;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.2);
    // box-shadow: var.$box-shadow;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    // background-color: #8a2be2;
  }

  &__svg {
    width: 5rem;
    height: 5rem;
    color: rgba(255, 255, 255, 0.2);
  }
}

.title {
  font-size: 2.8rem;
  font-weight: 600;
  word-break: break-word;
}

.info-container {
  display: flex;
  gap: 2rem;
}

.img-message {
  max-width: 45rem;
  text-align: center;
  margin: auto;
}

.description {
  overflow: hidden;
  word-break: break-word;
  max-height: 10rem;
  transition: all 0.6s;
  font-size: 1.6rem;

  &--hidden {
    // display: -webkit-box;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // -webkit-line-clamp: 8;
    // -webkit-box-orient: vertical;
  }

  // &--open {
  //   max-height: 100rem;
  // }

  &__btn-show {
    font-size: 1.6rem;
    color: var.$color-link;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: lighten(var.$color-link, 15%);
    }
  }

  & img {
    max-width: 100%;
    height: 50rem;
    // object-fit: cover;
    object-fit: contain;
    background: #323148;
    border-radius: 10px;
    padding: 1rem;
  }

  & a {
    @include var.linkA;
  }
  & p {
    word-break: break-word;
    margin-bottom: 2rem;
  }

  & ul {
    list-style-type: disc;
    margin-bottom: 16px;
    padding-left: 38px;
  }

  & li {
    margin-top: 10px;
  }

  & h2 {
    margin-top: calc(24px * 1.35);
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.35;
  }

  & code {
    white-space: break-spaces;
    line-height: 1.55;
    padding: 2px 6px;
    border-radius: 4px;
    color: #c1c2c5;
    background-color: var.$color-background-tertiary;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      Liberation Mono, Courier New, monospace;
    font-size: 12px;
    border: 1px solid var.$color-background-tertiary;
  }

  & * {
    max-width: 100%;
  }
}

// .info {
//   display: flex;
//   flex-direction: column;
//   font-size: 1.8rem;
//   padding: 1rem;
//   border: 1px solid #fff;
//   border-radius: 1rem;
//   gap: 1.4rem;
//   width: 20rem;
//   flex-shrink: 0;
// }

// .main-tag {
//   display: flex;
// }

// .tags {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// }

// .tag-sets__list {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   list-style: none;
// }

.img {
  width: 30rem;
  // height: 100%;
}

.images {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.versions-container {
  // display: flex;
  // gap: 1rem;
  // align-items: center;
}

.versions {
  overflow: hidden;
  padding-bottom: 2px;
  transition: all 0.3s ease;

  &__list {
    display: flex;
    gap: 2rem;
    list-style: none;
    flex-wrap: wrap;
    font-size: 1.6rem;
  }
}

.version {
  transition: all 0.3s;
  border-radius: 0.5rem;
  padding: 0.4rem;
  border: 1px solid transparent;
  cursor: pointer;

  &--active {
    border: 1px solid #8a2be2;
    background-color: rgb(34, 34, 34);
  }
  &--downloaded {
    color: blueviolet;
  }

  &:hover {
    border: 1px solid #8a2be2;
    background-color: transparent;
  }
}

.image-versions {
  display: flex;
  gap: 1rem;
}

.btn-examples {
  font-size: 2.6rem;
  cursor: pointer;
  background-color: rgb(82, 87, 87);
  border-radius: 1rem;
  padding: 0.2rem 0.6rem;
  &--active {
    color: blueviolet;
  }
}

.test {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  &__item {
    width: 30rem;
    height: 30rem;
    background-color: blueviolet;
  }
}

.link {
  // display: inline-block;
  // color: #7278f0;
  // text-decoration: none;
  @include var.linkA;
}

.active-corousel {
  position: sticky;
  top: 10rem;
  z-index: 14;
}

.btn-all {
  background-color: transparent;
  margin: auto;
  width: 12rem;
  box-shadow: none;
  margin-top: 0.6rem;
}

@media (max-width: 1000px) {
  .info-container {
    flex-direction: column;
  }
}
@media (max-width: 1000px) {
  .panel {
    grid-template-columns: 1fr 1fr;
  }
  .categories {
    grid-column: 1 / -1;
  }
  .btn-edit {
    grid-column: -1 / 2;
    grid-row: -1 / -2;
  }
  .btn-back {
  }
}

@media (max-width: 840px) {
  .title {
    font-size: 2.2rem;
  }
}

@media (max-width: 425px) {
  .img-container {
    justify-content: center;
  }
}

@include var.animation-show;
