@use "./../../variables/variables.scss" as var;

.btn-del {
  background-color: var.$color-background-red;

  &:hover {
    background-color: darken(var.$color-background-red, 10%);
  }
}

.del-request {
  max-width: 36rem;

  &__message {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
  }
}

.spinner-container {
  position: relative;
  height: 3rem;
}
