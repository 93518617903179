@use "../src/variables/variables.scss" as var;

@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/nunito/Nunito-Light.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/nunito/Nunito-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/nunito/Nunito-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/nunito/Nunito-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/nunito/Nunito-Bold.ttf) format("truetype");
  font-weight: 700;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 1.4rem;
  color: var.$color-text-white;
  // overflow-x: hidden;
  overflow: hidden scroll;
  background-color: var.$color-background-primary;
  // font-family: var.$font-family-primary;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include var.custom-scroll(#443d62);
}

main {
  display: flex;
  // padding-top: 2.2rem;
}

button {
  font-family: inherit;
  font-size: inherit;
}

.wrapper {
  width: 100%;
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.scroll-off {
  overflow: hidden;
  padding-right: 8px;
}

@-moz-document url-prefix() {
  @media (max-width: 1160px) {
    body {
      padding-right: 1.2rem;
    }
    main {
      display: flex;
      padding-right: 1.2rem;
    }
  }
}

@media (max-width: 1160px) {
  main {
    display: flex;
    padding-right: 2rem;
  }
}

@media (max-width: 768px) {
  main {
    padding-right: 2rem;
    // padding-top: 3.2rem;
  }
}

@media (max-width: 560px) {
  .wrapper {
    padding: 0 1rem;
  }
}
