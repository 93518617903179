@use "./../../../variables/variables.scss" as var;

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  animation: show 0.3s ease-in forwards;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.filter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.example-field {
  display: flex;
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.btn-secondary {
  font-size: 1.6rem;
  color: #818182 !important;
  text-align: right;
}

.checkbox {
  display: flex;
  font-size: 1.6rem;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
}

.versions {
  // height: 100%;
}

.submit {
  font-size: 1.6rem;
  min-height: 3.4rem;
}

.weight {
  display: flex;
  &__label {
    display: inline-block;
    font-size: 1.6rem;
    margin: 0 0.6rem 0.4rem;
  }
}
.subtitle {
  font-size: 2rem;
  border-bottom: 1px solid #949494;
  padding-bottom: 1rem;
  margin: 1rem 0 2rem;
}

@include var.animation-show;
