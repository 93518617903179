@use "./../../variables/variables.scss" as var;

.btn-secondary {
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  color: darken(var.$color-primary, 10%);
  font-weight: 600;
  font-family: inherit;
  // padding: 0.2rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: darken(var.$color-primary, 15%);
  }

  &:disabled {
    color: var.$color-gray;
  }
}
