@use "../../variables/variables.scss" as var;

.container {
  display: flex;
  position: sticky;
  // position: relative;
  // border-radius: 1rem;
  height: 100vh;
  top: 0;
  background-color: rgba(darken(var.$color-background-secondary, 5%), 0.7);
  // background-color: rgb(23 26 32 / 72%);
  z-index: 25;
  width: 20px;

  // &--open {
  //   width: auto;
  // }
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var.$color-background-secondary;
  //   border-left: 1px solid #fff;
  // width: 0px;
  // width: 30px;
  padding: 0rem;
  // overflow: hidden;
  flex-shrink: 0;
  padding: 1rem 1.5rem 0 1rem;
  transition: all 0.4s;

  &--open {
    // width: 30rem;
    // padding: 1rem 1.5rem 0 1rem;
  }
}

// .ref-images {
//   padding: 0.4rem;
//   display: flex;
//   gap: 1rem;
//   // justify-content: space-between;
//   background-color: rgba(darken(var.$color-background-secondary, 5%), 0.7);
//   background-color: var.$color-background-secondary;
//   border-radius: 10px;
//   width: 100%;
//   list-style: none;
//   // box-shadow: var.$box-shadow-light;

//   &__item {
//     position: relative;
//     width: 8rem;
//     border-radius: 10px;
//     overflow: hidden;
//     cursor: pointer;

//     &--def {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 8rem;
//       border-radius: 10px;
//       overflow: hidden;
//       background-color: rgba(117, 107, 118, 0.2);

//       & svg {
//         width: 2.6rem;
//         opacity: 0.3;
//       }
//     }
//   }

//   &__nsfw {
//     filter: blur(20px);
//   }
// }

.close {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #5966a1;
  }

  & svg {
    width: 2rem;
  }
}

.model-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: lighten(var.$color-background-secondary, 3%);
  border-radius: 1rem;
  flex-direction: column;
  gap: 1.2rem;
  height: 100%;
  overflow-y: scroll;
  // padding: 1rem;
  padding: 1rem 0.2rem 1rem 1rem;
  // margin-top: 1rem;
  width: 28rem;

  &__tip {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    color: var.$color-text-gray-dark;
    margin: auto;
    // margin-top: 70%;
    width: 15rem;
    text-align: center;
    margin-left: 1.5rem;
  }

  &:hover {
    // background-color: aliceblue;
  }

  @include var.custom-scroll // &::-webkit-scrollbar {
    //   width: 8px; /* width of the entire scrollbar */
    //   border-radius: 1rem;
    //   opacity: 0;
    // }
    // &::-webkit-scrollbar-track {
    //   background: #443d62; /* color of the tracking area */
    //   border-radius: 1rem;
    //   opacity: 0;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: #ababab; /* color of the scroll thumb */
    //   border-radius: 20px; /* roundness of the scroll thumb */
    //   opacity: 0;
    //   //   border: 3px solid #ababab; /* creates padding around scroll thumb */
    //   &:hover {
    //     background-color: #818181; /* color of the scroll thumb */
    //     opacity: 0;
    //   }
    // }
;
}

.plus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;

  & svg {
    width: 1.5rem;
  }
}

.options {
  position: relative;
  width: 28rem;

  &__btns {
    display: flex;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__type {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 100%;
  }

  &__type-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
    // color: #211657;
    white-space: nowrap;
    padding: 0.6rem;
    border: 2px solid rgba(var.$color-gray, 0.4);
    background-color: transparent;
    color: var.$color-text-white;
    border-radius: 1rem;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;

    &--active {
      border-color: var.$color-primary;
    }

    &:hover {
      border: 2px solid lighten(var.$color-primary, 20%);
      box-shadow: 0 0 1rem rgba(#211657, 0.3);
    }
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.6rem;
  margin-top: 1rem;

  & *:first-child {
    margin-right: auto;
  }

  &__btn {
    // color: var.$color-text-white;
    // background-color: #323148;
    // border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.6rem;
    // padding: 0.2rem 0.6rem;
    // cursor: pointer;
    // transition: all 0.3s;

    // &:hover {
    //   background-color: lighten(#323148, 5%);
    //   // background-color: #5f3d62;
    //   border: 1px solid rgba(255, 255, 255, 0.5);
    // }
    // &:active {
    //   border: 1px solid rgba(255, 255, 255, 0.3);
    //   background-color: darken(#323148, 5%);
    //   box-shadow: var.$box-shadow-inset;
    &--active {
      background-color: #5f3d62;
    }
  }

  &__clear {
    // display: flex;
    // align-items: center;
    gap: 0.4rem;
  }

  &__svg {
    width: 1.4rem;
    height: 1.4rem;
    // margin-right: 0.4rem;
  }
}

.btn__open {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
  // position: absolute;
  width: 2rem;
  height: 10rem;
  margin-top: 45vh;
  border: none;
  border-radius: 1rem 0 0 1rem;
  background-color: #756b76;
  background-color: var.$color-primary;
  color: var.$color-text-white;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: darken(#756b76, 5%);
    background-color: darken(var.$color-primary, 5%);
  }

  &:active {
    box-shadow: inset 0.2rem 0.3rem 0.5rem rgba(var.$color-black, 0.4);
  }

  & svg {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.btn-forms {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-left: auto;
  // position: absolute;
  // top: 1rem;
  // right: 1rem;
  // width: 12.5rem;
  // padding: 0.4rem 0.8rem;
  // background-color: rgb(101, 38, 160);
  // color: #fff;
  // border: none;
  // border-radius: 1rem;
  // cursor: pointer;

  & svg {
    width: 2rem;
    height: 2rem;
  }

  &--close {
    background-color: var.$color-background-red;

    &:hover {
      background-color: darken(var.$color-red, 15%);
    }
  }
}

.forms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;

  @include var.custom-scroll;
}

.controls__btn {
  display: inline-flex;
  align-items: center;
  margin-left: 0.3rem;
  padding: 0.3rem 0.4rem;
  & svg {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.support {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 28rem;
  margin-bottom: 0.4rem;
  line-height: 1;
}

@media (max-width: 1160px) {
  // @media (max-width: 1024px) {
  .container {
    position: fixed;
    right: 0;
    // padding-top: 5rem;
  }

  .panel {
    // padding-top: 2rem;
  }
}

@media (max-width: 425px) {
  .container {
    height: calc(100vh - 5rem);
    margin-top: 5rem;
  }
}

@-moz-document url-prefix() {
  @media (min-width: 768px) {
    .container {
      padding-right: 1.2rem;
    }
  }
}
