@use "./../../../variables/variables.scss" as var;

.container {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  // width: 26.6rem;
  cursor: pointer;

  &--shadow::before {
    content: "";
    position: absolute;
    z-index: 1;
    background: radial-gradient(rgba(0, 0, 0, 0.42) 30%, transparent 70%);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    opacity: 0;
    transition: all 0.5s ease;
  }

  &:hover::before {
    opacity: 1;
  }

  &:hover .btn-full {
    opacity: 1;
  }
  // background-color: var.$color-background-tertiary;

  & img {
    max-width: 100%;
  }
}

.image-svg {
  align-self: center;
  margin: auto;
  width: 25%;
  opacity: 0.6;
}

.menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0.4rem;
  background-color: rgb(var.$color-background-tertiary, 0.8) !important;
  width: 3.6rem;

  & svg {
    width: 2.4rem;
  }

  &:hover {
    background-color: rgb(var.$color-background-tertiary, 0.9) !important;
  }
  // position: absolute;
  // top: 1rem;
  // right: 1rem;
  // list-style: none;
}

.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 1.2rem;
  right: 1.2rem;
  list-style: none;
  z-index: 5;

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.4rem;
    list-style: none;
    border-radius: 1rem;
    // background-color: var.$color-background-tertiary;
    background-color: rgb(var.$color-background-tertiary, 0.9);
    padding: 1rem;
    box-shadow: var.$box-shadow;
  }

  &__item {
    cursor: pointer;
    transition: all 0.3s;

    &--del {
      color: var.$color-red;
    }

    &:hover {
      color: var.$color-text-violet;
    }
  }
}

.image {
  max-width: 100%;
  animation: show 0.4s ease-in forwards;
  &--hidden {
    display: none;
  }

  &--nsfw {
    filter: blur(20px);
  }
}

.image--portrait {
  position: absolute;
  min-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: #ab9db7;
  // z-index: -1;
}

.btn__set {
  position: absolute;

  cursor: pointer;
  &--previw {
    right: 2rem;
    bottom: 3rem;
  }
  &--nsfw-previw {
    left: 2rem;
    bottom: 3rem;
  }
}

.tag-sets {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  gap: 0.8rem;
  list-style: none;
  margin-top: 1rem;
  max-height: 55rem;
  // padding-right: 1rem;
  overflow-y: scroll;
  @include var.custom-scroll;

  &__modal {
    width: 60rem;
  }

  &__item {
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 1rem;
    overflow: hidden;
    background-color: var.$color-background-tertiary;
    // cursor: pointer;
    // transition: all 0.3s;

    // &:hover {
    //   color: var.$color-text-violet;
    // }
  }

  &__img {
    width: 9rem;
    flex-shrink: 0;
  }

  &__info {
    width: 100%;
    padding: 0.2rem 0.6rem;
  }

  &__name {
    font-size: 1.6rem;
    font-weight: 400;
  }

  &__btn-container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-top: 1rem;
  }
}

.tag-sets-head {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.tag-sets-title {
  font-size: 2.4rem;
  // line-height: 1;
}

.tag-sets-versions {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  &__item {
    cursor: pointer;
    border-radius: 0.5rem;
    padding: 0.4rem;
    border: 1px solid transparent;
    color: #8a2be2;
    transition: all 0.3s;

    &--active {
      border: 1px solid #8a2be2;
      background-color: rgb(34, 34, 34);
    }

    &:hover {
      border: 1px solid #8a2be2;
      background-color: transparent;
    }
  }
}

.mode-switch {
  display: flex;
  font-size: 1.4rem;
  border-radius: var.$border-radius;
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.btn-mode {
  // display: flex;
  width: 5rem;
  font-size: 1rem;
  background-color: transparent;
  // background-color: #fff;
  padding: 0.6rem 0.8rem;
  border: none;
  transition: all 0.3s;
  color: #fff;
  cursor: pointer;

  &--active {
    background-color: var.$color-primary;
  }
}

.btn-add {
  position: absolute;
  z-index: 4;
  top: 4.1rem;
  right: 1.2rem;
}

.btn-full {
  position: absolute;
  display: flex;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
  width: 100%;
  height: 100%;
  cursor: pointer;

  & svg {
    position: relative;
    width: 5rem;
    z-index: 2;
    color: rgb(var.$color-background-tertiary, 0.8);
    color: var.$color-text-gray;
    transition: all 0.4s ease;
    margin: auto;
    // color: rgb(var.$color-text-gray, 0.7);
  }

  &:hover svg {
    color: #fff;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: #ab9db7;
  z-index: -1;
  animation: loading 2s ease-in-out infinite;
}

.notification {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  // margin-bottom: 3rem;
  background-color: var.$color-background-tertiary;
  border-radius: 10px;
  padding: 1rem 0.9rem;

  &__text {
    color: var.$color-text-gray;
  }

  &__svg {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    color: var.$color-text-gray;
  }
}

.play-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 5rem;
  height: 5rem;

  &__svg {
    width: 2.8rem;
    height: 2.8rem;
  }
}

@keyframes loading {
  0% {
    background-color: #918b96;
  }
  50% {
    background-color: #b0b0b0;
  }

  100% {
    background-color: #918b96;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .tag-sets {
    grid-template-columns: 1fr;
  }
}
