@use "./../../variables/variables.scss" as var;

.card {
  // background-color: var.$color-background-primary;
  background-color: rgba(var.$color-background-primary, 80%);
  background-color: rgb(34 39 51);
  // background: linear-gradient(
  //   to bottom right,
  //   var.$color-background-secondary,
  //   #232835
  // );
  border-radius: 20px;
  overflow: hidden;
  padding: 2.8rem 4.8rem;
  // padding: 1rem 1rem;
  height: 100%;
  max-height: 95vh;
}

@media (max-width: 700px) {
  .card {
    padding: 3.6rem 3.2rem 2.6rem 3.2rem;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 2.8rem 1.6rem 1.6rem 1.6rem;
  }
}
