@use "../../../variables/variables.scss" as var;

.title {
  font-size: 2rem;
}

.tag-sets__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // flex-direction: column;
  overflow: hidden;
  gap: 2rem;
  list-style: none;
  //   max-height: 32rem;
  transition: all 0.4s;
  // @include var.pulse;

  //   &--open {
  //     max-height: 200rem;
  //   }
}

.tag-sets__wrap {
  position: relative;
}

.tag-sets__header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.tag-sets__container {
  max-height: 20rem;
  overflow: hidden;
  transition: all 0.6s;
  // display: flex;
}

.tag-sets {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: var.$color-background-tertiary;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: var.$box-shadow;

  &__img {
    width: 14rem;
    height: 20rem;
    background-color: lighten(var.$color-background-tertiary, 2%);
  }

  & ul {
    padding: 1rem;
    border-radius: 1rem;
    background-color: var.$color-background-tertiary;
    background-color: lighten(var.$color-background-tertiary, 2%);
  }

  &__tags {
    margin-top: 0.2rem;
    flex: 1;
  }

  &__btn {
    display: block;
    margin: 2rem auto 1rem;
  }

  &__btn--hidden {
    display: none;
  }
}

.notification {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  // margin-bottom: 3rem;
  background-color: var.$color-background-tertiary;
  border-radius: 10px;
  padding: 1rem 1.4rem;

  &__text {
    color: var.$color-text-gray;
  }

  &__svg {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    color: var.$color-text-gray;
  }
}

@media (max-width: 1250px) {
  .tag-sets__list {
    grid-template-columns: 1fr;
  }

  .tag-sets__btn--hidden {
    display: block;
  }
}

@media (max-width: 580px) {
  .tag-sets {
    flex-direction: column;
  }

  .tag-sets__img {
    width: 100%;
    height: auto;
    min-height: 40rem;
  }
}
