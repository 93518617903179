@use "../../variables/variables.scss" as var;

.tos {
  margin: 3rem auto;
  max-width: 93rem;
  font-size: 1.6rem;

  &__h1 {
    margin-bottom: 2rem;
    padding-left: 1rem;
    // margin-left: -1.4rem;
    border-left: 5px solid var.$color-primary;
  }

  //   &__h2 {
  //     margin-top: 4rem;
  //     margin-bottom: 2rem;
  //     padding-left: 1rem;
  //     border-left: 5px solid var.$color-primary;
  //   }
  //   &__h3 {
  //     display: inline-block;
  //     margin-top: 1rem;
  //     margin-bottom: 1rem;
  //     // margin-left: 1.4rem;
  //     padding-bottom: 0.5rem;
  //     border-bottom: 2px solid var.$color-primary;
  //   }

  &__text {
    margin-bottom: 2rem;
    // margin-left: 1.4rem;
  }

  &__link {
    @include var.linkA;
  }
}
