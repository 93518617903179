@use "./../../variables/variables.scss" as var;

.notification {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  background-color: var.$color-background-tertiary;
  border-radius: 10px;
  padding: 1rem 1.4rem;

  &__icon {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    color: var.$color-text-gray;
  }

  &__message {
    color: var.$color-text-gray;
  }
}

// @media (max-width: 510px) {
//   .notification {
//     grid-template-columns: 1fr;

//     &__icon {
//       display: none;
//     }
//   }
// }
