@use "./../../../variables/variables.scss" as var;

.footer {
  display: flex;
  padding: 1rem;
  align-items: center;
  // height: 5rem;
  background-color: var.$color-background-secondary;
  font-size: 1.4rem;

  &__copy {
    margin-right: auto;
    white-space: nowrap;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    margin-top: auto;
  }
}

.link {
  @include var.linkA;
}

@media (max-width: 430px) {
  .footer {
    flex-direction: column;

    &__content {
      gap: 1rem;
      justify-content: center;
    }

    &__copy {
      margin: auto;
      width: 100%;
      text-align: center;
    }
  }
}
