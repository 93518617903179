@use "../../../variables/variables.scss" as var;

.collections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
  margin: 2rem 0 2rem 0;
}

@media (max-width: 1040px) {
  .collections {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .collections {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .collections {
    grid-template-columns: 1fr;
  }
}
