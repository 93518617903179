@use "./../../../variables/variables.scss" as var;

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  margin-bottom: 2.2rem;
  z-index: 20;

  // transition: all 0.4s;
  // transition: all 0s;
  // transition: padding-right 0.4s;

  // &--open {
  //   // &--fixed.header--open {
  //   padding-right: 0rem;
  //   // width: calc(100% - 32rem);
  //   // transition: padding-right 0.4s;
  // }

  &--active {
    height: 100vh;
    padding-bottom: 0;
    // padding-bottom: 0.4rem;
  }

  &--fixed {
    position: fixed;
    padding-right: 2rem;
    // transition: all 0.4s;
  }

  &--aside-open {
    // &--fixed.header--open {
    // padding-right: 32rem;
    padding-right: var(--padding-right);
    // width: calc(100% - 32rem);
    // transition: padding-right 0.4s;
  }
}

// .header--fixed-open ~ main {
//   padding-top: 255.2px;
//   padding-top: 278px;
// }

// .header--fixed-closed ~ main {
//   padding-top: 50px;
//   padding-top: 90px;
//   padding-top: 70px;
// }
// .header--fixed-open[style="transition: all 0.4s ease 0s;"] ~ main {
//   padding-top: 50px;
// }
// @media (max-width: 1024px) {
//   .header {
//     height: 14rem;
//   }
// }

@media (max-width: 1160px) {
  .header {
    padding-right: 2rem;
    &--open {
      // padding-right: 2rem;
      // &--fixed.header--open {
      // padding-right: 2rem;
      // width: calc(100% - 32rem);
      // transition: padding-right 0.4s;
    }
  }
}

@media (max-width: 475px) {
  .header {
    padding-right: 0;
  }
}

@media (max-width: 375px) {
  .header {
    // height: 11rem;
  }

  .logo {
    font-size: 3.4rem;
  }
}
