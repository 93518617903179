@use "./../../variables/variables.scss" as var;

.container {
  width: 100%;
}

.tags {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 1rem;
  // padding: 1.4rem;
  // border-radius: 1rem;
  // background-color: rgb(var.$color-background-tertiary, 0.85);
  // box-shadow: var.$box-shadow-inset;
  // margin-top: 2rem;
  // padding-top: 1.6rem;
  // border-top: 1px solid #fff;
}

.btn-copy {
  color: #bdbdbd;
  font-size: 1.3rem;
  padding: 0.2rem 0.5rem;
  background: none;
  border: 1px solid var.$color-gray-dark;
  border-radius: 0.5rem;
  white-space: nowrap;
  margin-right: 0.4rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #7278f0;
    border-color: #7278f0;
  }

  &:active {
    color: #49ab6b;
    border-color: #49ab6b;
    transform: translateY(1px);
  }

  &--copied {
    color: #49ab6b;
    border-color: #49ab6b;
    &:hover {
      color: #49ab6b;
      border-color: #49ab6b;
    }
  }

  & svg {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.6rem;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  line-height: 1;

  & h3 {
    font-weight: 500;
  }
}

.coment {
  font-size: 1.4rem;
  font-weight: 400;
  color: var.$color-text-gray-dark;
  margin-left: 0.6rem;
}

@media (max-width: 375px) {
  .title__text {
    width: 100%;
  }
}
