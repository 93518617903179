@use "../../../variables/variables.scss" as var;

.activation-tag {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;

  &__container {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.tags {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;

  &__btn-edit {
    position: relative;
    display: flex;
    align-items: flex-end;
    gap: 0.6rem;
    font-size: 1.5rem;
    color: var.$color-text-violet;
    border: none;
    background-color: transparent;
    margin-left: auto;
    line-height: 1;
    // position: absolute;
    // top: 1rem;
    // right: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: var.$color-text-white;
    }

    & svg {
      width: 2rem;
    }

    &:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }

  &__btn-edit-name {
    display: block;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: var.$color-background-tertiary;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: var.$box-shadow;
    height: 100%;
  }

  &__notification {
    position: relative;
    height: 2.5rem;
    color: #898996;
    transition: all 0.3s ease;
    cursor: pointer;

    & svg {
      width: 2.5rem;
      height: 2.5rem;
    }
    &:hover {
      color: #aeaebc;
    }

    &:hover &-content {
      opacity: 1;
      z-index: 1;
    }
  }

  &__notification-content {
    position: absolute;
    top: -0.4rem;
    right: -0.4rem;
    transform: translate(100%, -100%);
    font-size: 1.4rem;
    width: 15rem;
    // max-width: 100%;
    padding: 1rem;
    border-radius: 10px 10px 10px 0;
    border: 1px solid var.$color-text-white;
    background-color: var.$color-background-tertiary;
    opacity: 0;
    z-index: -1;
  }

  &__notification-text {
    margin-bottom: 0.6rem;
  }

  &__param {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__field ul {
    padding: 1rem;
    border-radius: 1rem;
    background-color: lighten(var.$color-background-tertiary, 2%);
    // background-color: var.$color-background-tertiary;
  }

  &__form {
    width: 60rem;
    max-height: 80vh;
    padding-right: 1rem;
    overflow-y: scroll;
    @include var.custom-scroll;
  }
}

.title {
  font-size: 2rem;
}

.tag-set__list {
  display: grid;
  grid-template-columns: 1fr;
  // flex-direction: column;
  gap: 2rem;
  list-style: none;
}

.tag-set__container {
  // display: flex;
}

.tag-set {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: var.$color-background-tertiary;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: var.$box-shadow;
  &__img {
    width: 14rem;
    height: 20rem;
    background-color: lighten(var.$color-background-tertiary, 2%);
  }

  & ul {
    padding: 1rem;
    border-radius: 1rem;
    background-color: var.$color-background-tertiary;
    background-color: lighten(var.$color-background-tertiary, 2%);
  }

  &__tags {
    margin-top: 0.2rem;
    flex: 1;
  }

  &__btn {
    margin-top: 2rem;
    margin-left: auto;
  }
}

@media screen and (max-width: 600px) {
  .tags__param {
    // flex-wrap: wrap;
    // justify-content: flex-end;
    gap: 1rem;
  }

  .tags__btn-edit-name {
    display: none;
  }

  // .activation-tag {
  //   width: 100%;
  //   order: 2;
  // }
}
