@use "./../../variables/variables.scss" as var;

.container {
  width: auto;
}

.input {
  border-radius: 10px;
  padding: 0.5rem;
  width: 100%;
  padding-left: 1rem;
  background-color: var.$color-input-bg;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: var.$box-shadow-inset;
  color: var.$color-text-white;
  color: var.$color-text-gray;

  &:focus-visible {
    border: 1px solid var.$color-primary;
    outline: 1px solid var.$color-primary;
  }

  &--error {
    border: 1px solid rgba(var.$color-red, 0.6);
    // border: 1px solid var.$color-text-red;
  }

  &--read-only {
    background-color: lighten(var.$color-input-bg, 10%);
    cursor: default;

    &:focus-visible {
      border: 1px solid rgba(255, 255, 255, 0.3);
      outline: none;
    }
  }
}

.label {
  display: inline-block;
  font-size: 1.6rem;
  margin: 0 0.6rem 0.4rem;
}

.error {
  font-size: 1.4rem;
  color: var.$color-text-red;
  margin: 0.2rem 1.8rem 0rem;
}
