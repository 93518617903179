@use "./../../../variables/variables.scss" as var;

.form {
  min-width: 30rem;
}

.btn-submit {
  width: 100%;
  margin-top: 1rem;
}

.types {
  display: flex;
  gap: 1rem;
  align-items: center;
  border: none;
}

.type {
  // border: 1px solid var.$color-primary;
  // border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1rem 2rem;
  width: 100%;

  &-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 600;
    // color: #211657;
    white-space: nowrap;
    padding: 1.1rem;
    border: 2px solid rgba(var.$color-gray, 0.4);
    border-radius: 1rem;
    width: 100%;
    text-align: center;
    // box-shadow: 0 0 0.4rem rgba(166, 166, 166, 0.3);
    transition: all 0.3s;
    cursor: pointer;
  }

  & input {
    display: none;

    &:checked + label {
      border-color: var.$color-primary;
    }

    &:not(:checked) + label:hover {
      border: 2px solid lighten(var.$color-primary, 20%);
      box-shadow: 0 0 1rem rgba(#211657, 0.3);
    }
  }
}
