@use "./../../variables/variables.scss" as var;

// .category {
//   // display: flex;
// }

.subcategories-container {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // gap: 1.6rem;
  // box-shadow: inset 0rem 0rem 0.5rem rgba(0, 0, 0, 0.3);
  // margin: 0 0 2rem 0;
  // padding: 1rem;
  // border-radius: 1rem;
  // background-color: rgb(var.$color-background-tertiary, 0.6);
}

.subcategories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  gap: 0.8rem;
  // box-shadow: inset 0rem 0rem 0.5rem rgba(0, 0, 0, 0.3);
  // margin: 0 0 2rem 0;
  // padding: 1rem;
  // border-radius: 1rem;
  // background-color: rgb(var.$color-background-tertiary, 0.6);

  &__edit {
    display: flex;
    align-items: flex-end;
    gap: 0.6rem;
    border-radius: 5px;

    & svg {
      width: 1.6rem;
    }
  }
}

.subcategory__link {
  position: relative;
  font-size: 1.6rem;
  padding: 0.3rem 1rem;
  // border-left: 1px solid #fff;
  border-bottom: 1px solid transparent;
  border-right: 1px solid hsla(0, 0%, 100%, 0.3);
  cursor: pointer;
  line-height: 1;
  transition: all 0.3s;

  &:not(.active):hover {
    color: lighten(var.$color-primary, 20%);
  }

  &:last-child {
    border-right: none;
  }

  &--all {
    // padding-right: 1rem;
    // border-right: 1px solid var.$color-text-gray-dark;
    border: 1px solid var.$color-violet-grey;
    border-radius: 10px;
    padding: 0.2rem 1.3rem;
    font-size: 1.6rem;
    line-height: 1.3;
    transition: all 0.3s;

    &:not(.active):hover {
      border: 1px solid lighten(var.$color-primary, 20%);
    }

    &.active {
      border-color: #00ffff;
    }
  }

  &.active {
    color: #00ffff;
  }
}

@media (max-width: 480px) {
  .subcategories-container {
    gap: 0.4rem;
  }
}
