@use "../../variables/variables.scss" as var;

.container {
  position: relative;
  // margin-left: 7rem;
  width: 100%;
  max-width: 43rem;
  // height: 24rem;
  //   padding: 2rem 3rem;
  //   border-radius: 10px;
  //   background-color: var.$color-background-tertiary;
  //   background-color: rgba(var.$color-background-tertiary, 0.7);
  //   // background-color: lighten(var.$color-background-secondary, 2%);
}

.list {
  position: relative;
  list-style: none;
  padding-bottom: 50%;
  margin-left: 15%;
  // width: 43rem;
  // height: 24rem;
  //   margin: 2rem;

  &::before {
    content: "";
    position: absolute;
    top: 40%;
    left: -10%;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    background: radial-gradient(rgba(0, 0, 0, 0.2) 1%, transparent 60%);
    transform: rotateX(80deg);
  }
}

.item {
  position: absolute;
  top: 0;
  left: 0;
}

.image {
  width: 100%;
  box-shadow: var.$box-shadow;
  //   transform: skew(3deg, -9deg);
  //   transform: scale3d(0.5, 1, 1.7);
  -webkit-transform: perspective(11cm) rotateX(-7deg) rotateY(34deg);
  transform: perspective(11cm) rotateX(-7deg) rotateY(34deg);
  // transform: rotateX(-7deg) rotateY(34deg);
  //   border: 1px solid #fff;
}

@keyframes slideUp {
  0% {
    top: 0rem;
  }
  50% {
    top: 1rem;
  }
  100% {
    top: 0rem;
  }
}

@keyframes fadeOut {
  0% {
    // transform: translateX(0rem);
    opacity: 1;
  }

  100% {
    transform: translate(1rem, 1rem);
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    transform: translate(-4.2rem, -3.2rem);
    opacity: 0;
  }

  100% {
    transform: translate(-3.2rem, -3.2rem);
    opacity: 1;
  }
}
