@use "./../../../variables/variables.scss" as var;

.subcategories-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.6rem;
  font-size: 1.6rem;
  box-shadow: inset 0rem 0rem 0.5rem rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 1rem;
  background-color: rgb(var.$color-background-tertiary, 0.6);
}

.subcategories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  // gap: 0.8rem;
  // box-shadow: inset 0rem 0rem 0.5rem rgba(0, 0, 0, 0.3);
  // margin: 0 0 2rem 0;
  // padding: 1rem;
  // border-radius: 1rem;
  // background-color: rgb(var.$color-background-tertiary, 0.6);

  &__edit {
    display: flex;
    align-items: flex-end;
    gap: 0.6rem;
    border-radius: 7px;

    & svg {
      width: 1.6rem;
    }
  }
}
