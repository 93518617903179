@use "./../../../../variables/variables.scss" as var;
@include var.animation-show;

.guide__content {
  &--0 {
    top: 7.6rem;
    right: -1rem;
  }

  &--1 {
    top: -1.1rem;
    right: -4rem;
  }
}

@media (max-width: 600px) {
  .guide__content--1 {
    right: -2.8rem;
  }
}
