@use "./../../variables/variables.scss" as var;

.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  // background-color: rgba(0, 0, 0, 0.5);
  // -webkit-backdrop-filter: blur(12px);
  // backdrop-filter: blur(12px);
  z-index: 999;

  &__backdrop {
    position: absolute;
    top: 0;
    // left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    // z-index: 30;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }

  &__content {
    position: relative;
    z-index: 2;
    // top: 50%;
    // left: 50%;
    // left: auto;
    // margin: auto;
    // height: auto;
    height: auto;
    // transform: translate(-50%, -50%);
    // z-index: 40;
    max-width: 95vw;
    min-width: 31rem;
    max-height: 95vh;
    border-radius: 10px;
    box-shadow: var.$box-shadow;
    // overflow-y: scroll;
    // @include var.custom-scroll;
  }

  &__close {
    background: none;
    border: none;
    position: absolute;
    cursor: pointer;
    top: 0.6rem;
    right: 0.6rem;
    color: var.$color-text-gray;
    transition: all 0.3s;

    & svg {
      width: 2.4rem;
    }

    &:hover {
      color: lighten(var.$color-text-gray, 20%);
    }
  }
}

.test {
  position: relative;
  // z-index: 20;
}

.title {
  margin-bottom: 1rem;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
