@use "../../variables/variables.scss" as var;

.container {
  min-width: 26rem;
  max-width: 120rem;

  &--open {
    margin-top: 1rem;
    background-color: rgb(lighten(var.$color-background-secondary, 3%), 0.97);
    box-shadow: var.$box-shadow-light;
    position: sticky;
    padding: 2.8rem 0.2rem 1.8rem 1.2rem;
    gap: 1.6rem;
    left: 0;
    top: 10rem;
    width: 100%;
    animation: open 0.2s ease-in forwards;
    border-radius: 1rem;
    overflow: hidden;
    z-index: 5;
  }
}

.wrap {
  display: flex;
  gap: 2rem;
  z-index: 10;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s;
}

.carousel {
  position: relative;
  overflow: hidden;
  height: 39rem;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0rem 0rem 0.6rem 0.7rem rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  z-index: 2;
  animation: open 0.2s ease-in forwards;
}

.carousel__images {
  display: flex;
  gap: 1.4rem;
  position: absolute;
  align-items: center;
  height: 100%;
  transition: all 0.3s;

  &--hidden {
    opacity: 0;
  }
}

.btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #636363;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0.4rem;
  height: 4rem;
  width: 4rem;
  border: none;
  border-radius: 50%;
  box-shadow: var.$box-shadow-light;
  transition: background-color 0.1s;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &:active {
    transform: translateY(-48%);
  }

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  & svg {
    width: 2.4rem;
  }
}

.spiner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-add {
  position: absolute;
  z-index: 1;
  left: 1.6rem;
  top: 1.6rem;
}

.save {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 4rem;
  left: 0.5rem;
  top: 1.2rem;
  gap: 0.2rem;
  z-index: 1;
  width: 5rem;
  overflow: hidden;

  &:hover {
    overflow: visible;
  }

  &:hover &__btn--collection {
    top: 0;
    opacity: 1;
    z-index: 1;
  }

  &:hover .btn-save__amount {
    top: 7.8rem;
  }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.3s;
  }

  &__btn--collection {
    position: relative;
    // top: -4rem;
    left: 0;
    // opacity: 0;
    // z-index: -1;
  }

  &__btn--collection-hidden {
    top: -4rem;
    opacity: 0;
    z-index: -1;
  }

  &__btn-text {
    position: absolute;
    left: 100%;
    opacity: 0;
    color: #fff;
    text-shadow: #000000 1px 0 4px;
    white-space: nowrap;
    margin-left: 0.8rem;
    // background: radial-gradient(rgba(0, 0, 0, 0.6) 10%, transparent 80%);
    text-shadow: -1px -1px 3px rgba(32, 31, 47, 0.9),
      1px -1px 3px rgba(32, 31, 47, 0.9), -1px 1px 3px rgba(32, 31, 47, 0.9),
      1px 1px 3px rgba(32, 31, 47, 0.9);
    transition: all 0.3s;
  }

  &:hover &__btn-text {
    opacity: 1;
  }
}

.btn-save {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var.$color-text-white;
  border-radius: 1rem;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.4rem 0.4rem;
  font-size: 2.8rem;
  background-color: rgb(var.$color-background-tertiary, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.3);
  line-height: 1;
  font-family: serif;
  transition: all 0.3s;
  z-index: 1;
  cursor: pointer;

  & svg {
    width: 2rem;
  }

  &__amount {
    position: absolute;
    top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    min-width: 4rem;
    height: 2.2rem;
    padding: 0.4rem 0.4rem;
    background-color: rgb(var.$color-background-tertiary, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s;
  }

  &--saving {
    cursor: default;
  }

  &:not(&--saving, &:disabled):hover {
    background-color: var.$color-primary;
  }

  &:disabled {
    background-color: rgb(lighten(var.$color-background-tertiary, 20%), 0.8);
    cursor: default;
  }
}

.amount {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 5;
  background-color: rgba(55, 42, 199, 0.6);
  border-radius: 1rem;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
}

.pagination {
  display: flex;
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%);
  width: 85%;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
  list-style: none;
  gap: 0.2rem;

  &__item {
    width: 100%;
    height: 1rem;
    width: 100%;
    background-color: #4a4a4a;
    opacity: 0.8;
    border-radius: 1rem;
    cursor: pointer;

    &--active {
      background-color: #372ac7;
    }
  }
}

@keyframes open {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
