@use "./../../../variables/variables.scss" as var;

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 32rem;
  max-width: 100%;
}

.imputs-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fieldset {
  overflow-y: scroll;
  max-height: 40vh;

  @include var.custom-scroll;
}

.filter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.example-field {
  display: flex;
  gap: 1rem;
}

.btn-secondary {
  font-size: 1.6rem;
  color: #818182;
  text-align: right;

  &:hover {
    color: lighten(#818182, 15%);
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.checkbox {
  margin-left: 1rem;
  // display: flex;
  // font-size: 1.6rem;
  // padding: 1rem;
  // gap: 1rem;
  // align-items: center;
}

.submit {
  font-size: 1.6rem;
  min-height: 3.4rem;
}

.hidden {
  display: none;
}

.btn-back {
  position: absolute;
  top: 0.6rem;
  left: 1.4rem;
  // background-color: transparent;
  background: none;
  border: none;
  color: var.$color-text-gray;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: lighten(var.$color-text-gray, 20%);
  }

  & svg {
    width: 2rem;
    height: 2rem;
  }
}
