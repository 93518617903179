.maintenance {
  text-align: center;
  margin-top: 10%;
}

.title {
  font-size: 4.6rem;
}

.text {
  font-size: 2.4rem;
  margin-top: 2rem;
}
