@use "./../../variables/variables.scss" as var;

.tag {
  font-size: 1.4rem;
  // padding: 0.4rem;
  padding: 0.4rem 0.8rem;
  border: 1px solid rgb(255, 255, 255, 0.2);
  border: 1px solid transparent;
  // outline: 1px solid #fff;
  // border-radius: 5px;
  border-radius: 1rem;
  cursor: pointer;
  background-color: #323148;
  list-style: none;
  word-break: break-word;
  box-shadow: var.$box-shadow-light;
  word-spacing: 0.3rem;
  letter-spacing: 0.4px;
  transition: all 0.3s;

  &:hover {
    // background-color: #249e3e;
    // background-color: #252435;
    border: 1px solid rgb(255, 255, 255, 0.5);
  }

  &:active {
    // transform: translateY(1px);
    transform: scale(98%);
  }
}

.active {
  background-color: #48313f;
  // background-color: #314840;
}

.break {
  background-color: var.$color-primary;
}
