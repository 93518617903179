@use "./../../variables/variables.scss" as var;
@include var.animation-show;

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.section {
  //   display: flex;
  //   align-items: center;
  gap: 4rem;
  width: 100%;
  //   justify-content: center;
  flex-grow: 1;
  //   font-size: 1.6rem;
  margin: 1rem auto;
  margin-bottom: 2rem;
  padding: 6rem 6rem;
  border-radius: 10px;
  background-color: rgba(var.$color-background-tertiary, 0.7);
  background-color: #1f1931;
  background: linear-gradient(to top left, #1f1931, #5a3ab2);
  background: linear-gradient(to top left, #1f1931, #382a5f);
  background: linear-gradient(to bottom right, #1f1931, #382a5f);
  background: linear-gradient(to bottom right, #1f1931, #291f44);
  background: linear-gradient(
    to bottom right,
    var.$color-background-tertiary,
    #291f44
  );
  background: linear-gradient(
    to bottom right,
    var.$color-background-secondary,
    #232835
  );
  //   background: linear-gradient(
  //     to bottom right,
  //     var.$color-background-secondary,
  //     #262335
  //   );
  // background: linear-gradient(to top left, #1f1931, #3e255a);
  // background-color: rgba(var.$color-background-tertiary, 1);
  box-shadow: var.$box-shadow;
  box-shadow: var.$box-shadow-inset;
  animation: show 0.6s ease-in forwards;

  &__title {
    font-size: 2.8rem;
    padding-left: 1rem;
    border-left: 5px solid var.$color-primary;
    margin-bottom: 2.8rem;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 4rem;
    margin-top: 2rem;

    &__text {
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
    }
  }

  &__img-wrap {
    width: 100%;
    // min-width: 30rem;
    flex-shrink: 0;
  }

  &__img {
    width: 100%;
    box-shadow: var.$box-shadow;
  }
}

.section--hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  flex-grow: 1;
  font-size: 1.6rem;
  padding: 12rem 7rem;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 46rem;
    text-align: center;
  }

  &__text {
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
  }
}

.logo {
  max-width: 30rem;
  margin-bottom: 1rem;

  & img {
    width: 100%;
  }
}

.section--cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.8rem 6rem;
  width: 100%;

  &__content {
    width: 100%;
    grid-template-columns: 1fr auto;
  }
  &__text {
    max-width: 63rem;
    // font-size: 1.8rem;
  }
}

.section--hero__content .btn-hero {
  //   width: 20rem;
  width: 100%;
  padding: 1rem 2rem;
  // align-self: flex-end;
  // margin-right: 4rem;
  margin-top: 1rem;
  font-size: 1.6rem;
}

.section .section--cta__btn {
  max-width: 20rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  //   margin-bottom: 2rem;
}

.notification {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  // margin-bottom: 3rem;
  background-color: var.$color-background-tertiary;
  border-radius: 10px;
  padding: 1rem 0.9rem;

  &__text {
    color: var.$color-text-gray;
  }

  &__svg {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    color: var.$color-text-gray;
  }
}

.link {
  @include var.linkA;
}

@media (max-width: 1080px) {
  .section {
    &__content {
      grid-template-columns: 1fr;
      justify-items: center;
    }
    &__img-wrap {
      grid-row: 2;
    }
  }
}

@media (max-width: 975px) {
  .section--hero {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 10rem;
    padding: 6rem 6rem 12rem;

    &__content {
      grid-row: 1;
    }
  }
}

@media (max-width: 430px) {
  .section {
    padding: 4rem 4rem;
  }
  .section--hero {
    padding: 6rem 4rem 8rem;
  }

  .section .section--cta__btn {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 375px) {
  .section {
    padding: 3rem 3rem;

    &__title {
      font-size: 2.4rem;
    }

    &__content__text {
      //   font-size: 1.4rem;
    }
  }
  .section--hero {
    padding: 5rem 3rem 7rem;

    &__text {
      font-size: 1.6rem;
    }
  }
}
