@use "./../../variables/variables.scss" as var;

.wrap {
  @include var.wrapper;
  flex-shrink: 0;
  padding: 0;
  transition: none;
  // background-color: rgb(var.$color-background-primary, 0.9);
}

.prompt__content-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  user-select: none;
}

.container {
  display: grid;
  position: relative;
  bottom: 0;
  // overflow: hidden;
  // display: grid;
  grid-template-rows: 0fr auto;
  transition: all 0.3s;
  color: #fff;
  width: 100%;
  // will-change: grid-template-rows;
  // padding: 0 2rem;
  // z-index: 10;

  &--open {
    // transform: translateY(-100%);
    grid-template-rows: 1fr auto;
  }
}

.content {
  display: flex;
  // position: relative;
  flex-direction: column;
  // background-color: rgb(var.$color-background-primary, 0.9);
  border-radius: 0 0 10px 10px;
  gap: 0.6rem;
  // height: 0px;
  width: 100%;
  // padding: 1rem;
  overflow: hidden;
  padding: 0 2rem;
  background-color: rgb(var.$color-background-primary, 0.9);
  transition: all 0.3s;
}

.settings {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
}

.label {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
}

.mode-switch {
  display: flex;
  // font-size: 1.4rem;
  border-radius: var.$border-radius;
  border: 1px solid rgba(255, 255, 255, 0.3);
  // width: 10rem;
  overflow: hidden;
  transition: all 0.3s;
}
.btn-mode {
  width: 5rem;
  font-size: 1.2rem;
  // text-transform: uppercase;
  background-color: transparent;
  // background-color: #fff;
  padding: 0.4rem 0.6rem;
  border: none;
  transition: all 0.3s;
  color: #fff;
  cursor: pointer;

  &:not(.btn-mode--active):hover {
    background-color: var.$color-background-secondary;
  }

  &--active {
    background-color: var.$color-primary;
  }
}

.btn-type-container {
  margin-left: 1rem;
  display: inline-block;
}

.prompt {
  position: relative;
  max-height: calc(100vh - 300px);
  height: 10rem;
  min-height: 10rem;

  &--neg {
    max-height: 180px;
    height: 6rem;
    min-height: 6rem;
  }

  &__resize-box {
    width: 15px;
    height: 15px;
    // background-color: gray;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: n-resize;
    z-index: 20;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      border: 1px solid var.$color-text-gray;
      width: 100%;
      transform: rotate(130deg) translate(-10%, 80%);
    }

    &::after {
      position: absolute;
      top: 70%;
      left: 40%;
      content: "";
      border: 1px solid var.$color-text-gray;
      width: 50%;
      transform: rotate(130deg) translate(-10%, 80%);
    }
  }

  &__textarea {
    padding: 0.6rem 0.8rem;
    background-color: rgb(var.$color-background-tertiary, 0.9);
    opacity: 0.9;
    border: 1px solid var.$color-violet-grey;
    border-radius: 1rem 0rem 0rem 1rem;
    color: var.$color-text-white;
    font-size: 1.4rem;
    line-height: 1.4;
    // height: 10rem;
    resize: none;
    flex-grow: 1;
    font-family: "Segoe UI", sans-serif;
    word-spacing: 0.4rem;
    letter-spacing: 0.1px;
    // max-height: calc(100vh - 300px);
    // height: 10rem;
    // min-height: 10rem;
    width: 100%;
    height: 100%;
    @include var.custom-scroll;

    &::placeholder {
      color: var.$color-text-gray-dark;
    }

    &:focus {
      outline: none;
      border: 1px solid #a4a4a4;
    }
  }
}

.field {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4rem;
  // height: 10rem;
  // &--neg {
  //   height: 6rem;
  // }
}

.tagarea {
  height: 100%;
  width: 100%;
  border-radius: 1rem 0rem 0rem 1rem;
  background-color: rgb(var.$color-background-tertiary, 0.8);
  // opacity: 0.85;

  // &--neg {
  //   height: 6rem;
  // }
}

// .btn-prompt-type {
//   border: 1px solid rgb(255, 255, 255, 0.3);
//   color: #fff;
//   // font-size: 1.3rem;
//   // background-color: transparent;
//   background-color: var.$color-background-secondary;
//   padding: 0.3rem 0.8rem;
//   transition: all 0.3s;
//   &--left {
//     border-radius: var.$border-radius 0 0 var.$border-radius;
//   }
//   &--right {
//     border-radius: 0 var.$border-radius var.$border-radius 0;
//   }
//   &--active {
//     background-color: var.$color-primary;
//   }
// }

.clear {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
  // height: 100%

  &__title {
    display: inline-block;
  }

  &--fields {
    display: flex;
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    // margin-left: 1rem;
    padding: 0 0.8rem;
    height: 100%;
  }

  &__btn-svg {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.btn-open {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: var.$color-text-white;
  font-size: 1.4rem;
  width: 18rem;
  margin: 0 4rem 0 auto;
  background-color: #756b76;
  background-color: var.$color-primary;
  height: 2rem;
  border: none;
  border-radius: 0 0 1rem 1rem;
  cursor: pointer;
  margin-bottom: -100%;
  position: relative;
  transition: all 0.3s;
  z-index: 19;

  &:hover {
    background-color: darken(var.$color-primary, 5%);
  }

  &:active {
    box-shadow: inset 0.2rem 0.3rem 0.5rem rgba(var.$color-black, 0.4);
  }

  & svg {
    width: 1.8rem;
  }
}

.btn-copy {
  border: 1px solid #fff;
  border-radius: 0 1rem 1rem 0;
  cursor: pointer;
  border: 1px solid rgb(255 255 255 / 30%);
  background-color: #323148;
  border-radius: 0 1rem 1rem 0;
  color: #cdcbcb;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: lighten(#323148, 5%);
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &:active {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: darken(#323148, 5%);
    box-shadow: var.$box-shadow-inset;
  }

  & svg {
    width: 2rem;
  }
}

// .btn-clear {
//   display: flex;
//   align-items: center;
//   gap: 0.4rem;
//   margin-left: 1rem;
//   padding: 0 0.8rem;
//   height: 100%;

//   &__svg {
//     width: 1.2rem;
//     height: 1.2rem;
//   }
// }

.copied {
  // color: #27bb2c;
  color: var.$color-text-green;
}

.plus-icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.2rem;
}

@media (max-width: 1024px) {
  .btn-open {
    // right: 50%;
    margin-right: 50%;
    transform: translateX(50%);
  }
}

@media (max-width: 768px) {
  .btn-open {
    width: 15rem;
    height: 2.2rem;
  }

  .clear--fields {
    display: none;
  }
}

@media (max-width: 480px) {
  .prompt {
    position: relative;
    max-height: calc(100vh - 300px);
    height: 10rem;
    min-height: 17rem;

    &--neg {
      min-height: 9rem;
    }
  }
  .field {
    height: 17rem;
    &--neg {
      height: 9rem;
    }
  }

  .label {
    display: none;
  }

  .clear__title {
    display: none;
  }

  .settings {
    gap: 0.6rem;
    margin-left: 0;
  }

  .btn-open {
    width: 14rem;
    height: 2.2rem;
  }
}
