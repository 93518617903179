@use "./../../variables/variables.scss" as var;

.link {
  @include var.linkA;
  display: inline-flex;
  align-items: center;
  gap: 0.6rem;

  &__external-icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-bottom: 0.2rem;
  }
}
