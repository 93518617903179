@use "./../../../variables/variables.scss" as var;

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.4rem;
  animation: show 0.3s ease-in forwards;
}

.fields {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  &--guide {
    @include var.pulse;

    &::before {
      transform: translate(-50%, -50%) scale(1.02);
    }

    &::after {
      transform: translate(-50%, -50%) scale(1.02);
    }
  }
}

.filter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.example-field {
  display: flex;
  gap: 1rem;
}

.tagset {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-size: 1.6rem;
  }
}

.btn-secondary {
  font-size: 1.6rem;
  color: #818182;
  text-align: right;

  &:hover {
    color: var.$color-primary;
  }
}

.checkbox {
  display: flex;
  font-size: 1.6rem;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
}

.versions {
  flex-direction: row;
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  // height: 100%;
}

.submit-container {
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  bottom: 0.4rem;
}

.submit {
  display: flex;
  // position: sticky;
  // bottom: 0;
  // height: 3.2rem;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  min-height: 3.4rem;
  width: 100%;
  // width: 12rem;
  // align-self: flex-end;
}

.weight {
  display: flex;
  gap: 1rem;
  &__label {
    display: inline-block;
    font-size: 1.6rem;
    margin: 0 0.6rem 0.4rem;
  }
}
.subtitle {
  font-size: 2rem;
  border-bottom: 1px solid #949494;
  padding-bottom: 1rem;
  margin: 1rem 0 2rem;
}

.link {
  @include var.linkA;
  font-size: 1.4rem;
  flex-shrink: 0;
}

.status {
  display: flex;
  gap: 1rem;
  align-items: center;
  // justify-content: space-between;

  &__message {
    width: 100%;
  }
}

.subcategory {
  display: flex;
  gap: 0.6rem;

  &__input {
    width: 100%;
  }
}

.input__btn-del {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0.2rem;
  flex-shrink: 0;

  & svg {
    color: var.$color-gray;
    width: 1.8rem;
    height: 1.8rem;
  }
}

@include var.animation-show;
