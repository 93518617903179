@use "./../../variables/variables.scss" as var;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  font-size: 2.8rem;
  color: var.$color-text-white;
  border-radius: 1rem;
  background-color: rgb(var.$color-background-tertiary, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 3.6rem;
  height: 3.6rem;
  line-height: 1;
  font-family: serif;
  cursor: pointer;
  transition: all 0.3s;
  flex-shrink: 0;

  &:hover {
    background-color: var.$color-primary;
  }

  &--disabled {
    background-color: var.$color-text-gray;
    cursor: default;
    &:hover {
      background-color: var.$color-text-gray;
    }
  }
}
