@use "./../../variables/variables.scss" as var;

.checkbox {
  border: 1px solid rgba(255, 255, 255, 0.3);
  appearance: none;
  background-color: var.$color-background-light;
  background-color: var.$color-input-bg;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.4rem;
  display: grid;
  place-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.checkbox:focus {
  outline: max(2px, 0.2rem) solid var.$color-gray;
  outline-offset: max(1.4px, 0.1rem);
}

.checkbox:disabled {
  background-color: var.$color-gray;
  cursor: not-allowed;
}

.checkbox::before {
  content: "";
  width: 1.4rem;
  height: 1.4rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em lighten(var.$color-primary, 20%);
  clip-path: polygon(31% 44%, 46% 72%, 70% 12%, 100% 2%, 48% 98%, 2% 40%);
}

.checkbox:checked::before {
  transform: scale(1);
}

.label {
  display: flex;
  /* grid-template-columns: 1em auto; */
  gap: 1rem;
  // text-transform: capitalize;
  font-size: 1.6rem;
  align-items: center;
  cursor: pointer;
}
