@use "../../variables/variables.scss" as var;

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  // gap: 2rem;
  position: relative;
}

.inputs {
  width: 60rem;
  max-width: 100%;
  max-height: 70vh;
  padding-right: 1rem;
  overflow-y: scroll;
  @include var.custom-scroll;
}

.subtitle {
  position: absolute;
  font-size: 1.4rem;
  top: 0;
  right: 0;
  color: #818182;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.fieldset {
  position: relative;
}

.filter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.example-field {
  display: flex;
  gap: 1rem;
}

.tagset {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-size: 1.6rem;
  }
}

.fieldset .btn-secondary {
  font-size: 1.6rem;
  color: var.$color-text-gray;
  //   text-align: right;
  border: 1px solid var.$color-primary;
  width: 14rem;
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  text-align: center;
  align-self: flex-end;

  &:hover {
    color: lighten(var.$color-text-gray, 20%);
    border-color: lighten(var.$color-primary, 20%);
  }

  &:active {
    scale: 0.9;
  }
}

.checkbox {
  display: flex;
  font-size: 1.6rem;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
}

.versions {
  // height: 100%;
}

.submit {
  font-size: 1.6rem;
  min-height: 3.4rem;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
}

.weight {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  &__label {
    display: inline-block;
    font-size: 1.6rem;
    margin: 0 0.6rem 0.4rem;
  }
}

.input__btn-del {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0.2rem;
  flex-shrink: 0;
  background-color: #822929;
  //   border: 1px solid var.$color-background-red;

  &:hover {
    background-color: lighten(#822929, 10%);
  }

  & svg {
    color: var.$color-gray;
    width: 1.8rem;
    height: 1.8rem;
  }
}
