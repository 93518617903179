@use "./../../../variables/variables.scss" as var;

.form {
  display: flex;
  // flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  // width: 50rem;
  // width: 100%;
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1rem;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // width: 100%;
    // max-width: 55rem;
  }
}

.subcategory {
  display: flex;
  gap: 0.6rem;

  &__input {
    width: 100%;
  }
}

.input__btn-del {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0.2rem;
  flex-shrink: 0;

  & svg {
    color: var.$color-gray;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.btn-secondary {
  font-size: 1.6rem;
  color: #818182;
  text-align: right;

  &:hover {
    color: var.$color-primary;
  }
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  min-height: 3.4rem;
  width: 100%;
}

.status {
  width: 100%;
  // &__message {
  //   width: 100%;
  // }
}

@media (max-width: 768px) {
  .fields {
    grid-template-columns: 1fr;
  }
}
