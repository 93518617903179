@use "../../variables/variables.scss" as var;

.categories-container {
  margin-bottom: 2rem;
}

.categories {
  display: flex;
  gap: 2rem;
  list-style: none;
}

.category {
  font-size: 1.8rem;
  cursor: pointer;

  &--active {
    color: #025ec2;
  }
}

.subcategory {
  // font-size: 1.8rem;
  // cursor: pointer;
  padding: 0.2rem 0.8rem;
  margin: 0.6rem 0;

  &--border:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    // border-right: 1px solid #9e9e9e;
  }

  &--active {
    color: #00ffff;
  }
}

.collections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.notification {
  margin-top: 1rem;
}
