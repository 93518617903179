@use "../../../variables/variables.scss" as var;

.card {
  display: grid;
  position: relative;

  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  min-height: 38rem;
  overflow: hidden;
  // transition: all 0.3s;
  box-shadow: var.$box-shadow-dark;
  box-shadow: var.$box-shadow;
  background-color: var.$color-background-tertiary;
  word-break: break-word;
  height: 100%;
  // animation: show 0.3s ease-in forwards;

  &--motion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  &__image {
    transition: all 0.3s;
    &:hover img {
      transform: scale(1.04);
    }
  }

  &__content {
    position: absolute;
    bottom: -1px;
    width: 100%;
    text-decoration: none;
    color: var.$color-text-white;
    padding: 1rem 1rem;
    font-size: 1.4rem;
    background: linear-gradient(transparent, #000);
  }
}

.btn-add {
  position: absolute;
  top: 1rem;
  // right: 1rem;
  left: 1rem;
  z-index: 1;
}

.link {
  text-decoration: none;
  color: var.$color-text-white;
}

.image-container {
  position: relative;
}

.img {
  display: flex;
  // width: 12rem;
  // height: 100%;
  min-height: 14rem;
  min-width: 16rem;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #383838;
  cursor: pointer;
  overflow: hidden;

  & img {
    position: absolute;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
    // transition: all 0.3s;
  }

  &--hidden {
    opacity: 0;
  }
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(69, 64, 86);
  // opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  // z-index: 222;
  & svg {
    width: 5rem;
    height: 5rem;
    animation: rotate 1s linear infinite;
  }
}

.title-container {
  display: flex;
  align-items: center;
  max-height: 6rem;
  overflow: hidden;
}

.title {
  display: -webkit-box;
  font-size: 2.2rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s;

  &:hover {
    color: lighten(var.$color-primary, 20%);
  }
}

.text {
  font-size: 1.6rem;
}

.text-secondary {
  color: var.$color-text-gray;
  @include var.ellipsis(2);
}

.content {
  display: flex;
  flex-direction: column;
  // grid-template-rows: auto auto auto auto auto 1fr;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 1.8rem;
  // transition: all 0.3s;

  // &--open {
  //   grid-template-rows: auto auto auto auto 1fr 1fr;
  // }
}

.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 1.6rem;
}

.btn {
  color: #fff;
  font-size: 1.6rem;
  background-color: cadetblue;
  border: none;
  border-radius: 0.4rem;
  padding: 0.4rem;
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes showGradient {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .btn-add {
    top: 0.4rem;
    left: 0.4rem;
  }
}

@include var.animation-show;
