@use "./../../../variables/variables.scss" as var;

.auth {
  // margin: 0 3.6rem;
  margin: 0;
  width: 36rem;
  max-width: 100%;

  &__title {
    font-size: 3.6rem;
    margin-bottom: 2.4rem;
    font-weight: 500;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.6rem;
  }

  &__input {
    padding: 1rem;
    font-size: 1.6rem;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__error {
    // font-size: 2rem !important;
  }

  &__btn--switch {
    background-color: transparent;
    border: none;
    // color: darken(var.$color-primary, 10%);
    color: lighten(var.$color-primary, 10%);
    font-weight: 600;

    &:hover {
      color: lighten(var.$color-primary, 25%);
    }

    &:disabled {
      color: var.$color-gray;
    }
  }

  &__btn--submit {
    position: relative;
    padding: 0.8rem 2.6rem;

    &:disabled {
      color: transparent;
      background-color: var.$color-gray;
      box-shadow: none;
    }
  }
}

.invalid {
  background-color: lighten(var.$color-red, 55%);
  border-color: var.$color-red;
}

.link {
  @include var.linkA;
}

.privacy {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgb(var.$color-text-white, 60%);
}

.icon {
  width: 2rem;
  margin-right: 1rem;
}

.reset {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}
