@use "./../../../../variables/variables.scss" as var;
@include var.animation-show;

.guide__content {
  &--0 {
    top: -12.6rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &--1 {
    top: -11.1rem;
    left: 20rem;
  }

  &--2 {
    top: -11.4rem;
    left: 0rem;
  }

  &--3 {
    top: -25rem;
    left: 0rem;
  }
}

.svg {
  width: 2rem;
  height: 2rem;
  transform: translateY(3px);
}

.svg-dots {
  width: 2.4rem;
  height: 2.4rem;
  transform: translateY(7px);
}

@media (max-width: 1160px) {
  .guide__content--2 {
    // top: 7.6rem;
  }
}

@media (max-width: 600px) {
  // .guide__content--1 {
  //   left: 1rem;
  // }

  // .guide__content--1 .guide__arrow-bg {
  //   top: -1rem;
  //   left: 6rem;
  // }

  .guide__content--2 {
    left: 3rem;
  }

  .guide__content--2 .guide__arrow-bg {
    top: -1rem;
    left: 19.1rem;
  }
}
