@use "./../../variables/variables.scss" as var;

.btn {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: var.$color-text-white;
  font-family: var.$font-family-primary;
  font-size: 1.4rem;
  font-weight: 500;
  // min-height: 3.2rem;
  background-color: var.$color-primary;
  padding: 0.6rem 1.4rem;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0.2rem 0.5rem rgba(var.$color-black, 0.2);
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:hover {
    background-color: darken(var.$color-primary, 10%);
  }

  &:active {
    box-shadow: inset 0.2rem 0.3rem 0.5rem rgba(var.$color-black, 0.4);
  }

  &:disabled {
    background-color: var.$color-gray;
    box-shadow: none;
    cursor: not-allowed;
  }
}
