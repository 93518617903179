@use "../../variables/variables.scss" as var;

.card {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  //   grid-template-columns: 2fr 3fr;
  // border: 1px solid rgba(255, 255, 255, 0.3);
  border: 1px solid var.$color-text-gray-dark;
  border-radius: 1rem;
  overflow: hidden;
  // transition: all 0.3s;
  background-color: var.$color-background-tertiary;
  // margin: 1rem 0 1rem 0.6rem;
  // margin-left: 1rem;
  box-shadow: var.$box-shadow;
  flex-shrink: 0;
  // padding-bottom: 0.8rem;

  &--motion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // z-index: 999;
  }

  &--active {
    border: 1px solid var.$color-primary;
  }

  &--hidden {
    opacity: 0;
    z-index: -1;
  }
}

.head {
  display: grid;
  //   align-items: center;
  position: relative;
  // gap: 1rem;
  grid-template-columns: 8rem 1fr;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 2.6rem;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(
      to left,
      var.$color-background-tertiary 30%,
      transparent 110%
    );
    // z-index: 2;
  }
}

.btn__close {
  position: absolute;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  right: 0.2rem;
  top: 0;
  z-index: 2;
  transition: all 0.3s;

  &:hover {
    color: #5966a1;
  }
}

.link {
  text-decoration: none;
  color: var.$color-text-white;
}

.image {
  transition: all 0.3s;

  &:hover img {
    transform: scale(1.05);
  }
}

// .img {
//   display: flex;
//   // width: 12rem;
//   // height: 100%;
//   min-height: 9rem;
//   position: relative;
//   align-items: center;
//   justify-content: center;
//   background-color: #383838;
//   cursor: pointer;
//   overflow: hidden;

//   & img {
//     position: absolute;
//     min-height: 100%;
//     max-width: 100%;
//     object-fit: cover;
//     transition: all 0.3s;
//   }

//   &--hidden {
//     opacity: 0;
//   }
// }

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(69, 64, 86);
  // opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  // z-index: 222;
  & svg {
    width: 5rem;
    height: 5rem;
    animation: rotate 1s linear infinite;
  }
}

.base-info {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;

  &__item {
    color: var.$color-text-gray;
    background-color: #323148;
    padding: 0.4rem 0.8rem;
    border-radius: 10px;
    font-size: 1.2rem;
    white-space: nowrap;
  }

  &__item--type {
    background-color: rgba(137, 43, 226, 0.7);
    color: var.$color-text-white;
    text-transform: uppercase;
  }
}

.title-container {
  display: flex;
  align-items: center;
  height: 5rem;
  overflow: hidden;
}

.title {
  display: -webkit-box;
  font-size: 1.8rem;
  // margin-top: 1.2rem;
  // margin-bottom: 1.2rem;
  // align-self: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s;

  &:hover {
    color: lighten(var.$color-primary, 20%);
  }
}

.version-name {
  display: -webkit-box;
  word-break: break-all;
  color: var.$color-text-gray;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cont {
  padding-bottom: 1rem;
}

.content {
  display: flex;
  font-size: 1.4rem;
  flex-direction: column;
  // grid-template-rows: auto auto auto auto auto 1fr;
  align-items: flex-start;
  gap: 1rem;
  // padding: 1rem;
  transition: all 0.3s;

  // &--open {
  //   grid-template-rows: auto auto auto auto 1fr 1fr;
  // }
}

.info {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  font-size: 1.4rem;
  overflow: hidden;
}

.main-tag {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
  gap: 1rem;
  list-style: none;
  padding: 1rem 1rem 0 1rem;
}

.tag-list h3 {
  font-size: 1.4rem;
}

.tags {
  position: relative;
  // display: flex;
  // flex-wrap: wrap;
  // list-style: none;
  // gap: 1rem;
  max-height: 6.6rem;
  // height: 30%;
  overflow: hidden;
  transition: all 0.3s;

  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__list {
    opacity: 1;
    // padding-bottom: 1rem;
    padding: 0 1rem;
    padding-bottom: 0.8rem;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    color: #fff;
    width: 100%;
    background-color: #3e445e;
    border: none;
    border-radius: 0 0 1rem 1rem;
    cursor: pointer;
    position: relative;

    &--shadow::after {
      content: "";
      position: absolute;
      top: -2rem;
      opacity: 1;
      height: 2rem;
      width: 100%;
      background: linear-gradient(to top, #3e445e, transparent);
      animation: showGradient 1s ease forwards;
    }
  }

  &--open ~ &__btn {
    margin-top: 1rem;
  }

  &--open {
    opacity: 1;
    // height: 20rem;
    // height: 100%;
  }
}

.shadow {
  opacity: 1;
}

.weight {
  display: flex;
  gap: 0.8rem;
  padding: 0 1rem;

  &__value {
    color: var.$color-text-gray;
  }
}

.tags:not(.tags--open) .shadow::after {
  content: "";
  // position: absolute;
  // top: 4.6rem;
  // opacity: 1;
  // height: 2rem;
  // width: 100%;
  // background: linear-gradient(to top, #3e445e, transparent);
  // animation: showGradient 1s ease forwards;
}

.btn {
  color: #fff;
  font-size: 1.6rem;
  background-color: cadetblue;
  border: none;
  border-radius: 0.4rem;
  padding: 0.4rem;
  cursor: pointer;
}

.helper-tags {
  height: 0px;
  overflow: hidden;
  transition: all 0.8s;
  &--open {
    // height: 100%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes showGradient {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
