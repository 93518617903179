@use "./../../../../variables/variables.scss" as var;
@include var.animation-show;

.guide__content {
  &--default {
    top: 3.5rem;
    left: calc(100% - 19rem);
  }

  &--0 {
    top: 5.6rem;
    right: -1rem;
  }

  &--1 {
    top: -0.1rem;
    left: 25rem;
  }

  &--2 {
    top: 5.4rem;
    left: calc(100% - 30rem);
  }
}

.svg {
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(2px);
  // color: var.$color-text-yellow;
}

@media (max-width: 1160px) {
  .guide__content--2 {
    // top: 7.6rem;
  }
}

@media (max-width: 600px) {
  // .guide__content--1 {
  //   left: 1rem;
  // }

  // .guide__content--1 .guide__arrow-bg {
  //   top: -1rem;
  //   left: 6rem;
  // }

  .guide__content--2 {
    left: 3rem;
  }

  .guide__content--2 .guide__arrow-bg {
    top: -1rem;
    left: 19.1rem;
  }
}
