@use "./../../../variables/variables.scss" as var;

.nav-profile-container {
  position: relative;
  // width: 4.6rem;
  // height: 4.4rem;
  // margin-right: 2rem;
}

.nav-profile {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 20px 20px 0 0;
  padding: 0.7rem 1.5rem;
  transition: all 0.3s;
  z-index: 60;

  &__user {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var.$color-text-light;
    font-size: 1.4rem;
  }

  &__name {
    display: flex;
    gap: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    align-items: center;
    min-width: 3rem;

    & span {
      word-break: break-all;
      @include var.ellipsis(1);
    }
  }

  & svg {
    height: 3rem;
    width: 3rem;
    color: var.$color-text-light;
    flex-shrink: 0;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    height: 0rem;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    left: 0;
    transition: all 0.3s;
    box-sizing: border-box;
    text-align: center;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 500;
    list-style: none;
  }

  &__link {
    font-size: 1.6rem;

    & a:link,
    & a:visited {
      display: inline-block;
      color: var.$color-text-primary;
      padding: 0.3rem;
      width: 100%;
      text-decoration: none;
      transition: all 0.3s;
    }

    & a:hover,
    & a:active {
      color: var.$color-primary;
    }
  }

  &:hover &__menu {
    height: 7rem;
    background-color: var.$color-background-light;
  }

  &:hover &__user {
    color: var.$color-text-primary;
  }
  &:hover &__user svg {
    color: var.$color-text-primary;
  }
  &:hover &__name {
    display: block;
  }

  &:hover {
    background-color: var.$color-background-light;
    color: var.$color-text-primary;
  }
}
.nav-btn-auth {
  margin-top: 1rem;
  color: #b12727;
}

@media (max-width: 480px) {
  .nav-profile-container {
    width: 4.6rem;
    height: 4.4rem;
    margin-right: 1rem;
    margin-left: 6rem;
  }

  .nav-profile {
    position: absolute;
    right: 0;
    padding: 0.7rem 0.8rem;
    &__name {
      display: none;
      font-size: 1.2rem;
    }
  }
}
