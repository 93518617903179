@use "./../../../variables/variables.scss" as var;

.mobile-nav {
  position: fixed;
  display: none;
  top: 9px;
  left: 2rem;
  z-index: 9999;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &,
    &::before,
    &::after {
      display: inline-block;
      width: 1.8rem;
      height: 0.1rem;
      background-color: var.$color-background-light;
      transition: all 0.3s;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
    }

    &::before {
      top: -0.6rem;
    }
    &::after {
      top: 0.6rem;
    }
  }

  &__button {
    position: relative;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    background-color: var.$color-primary;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 0.6rem 1.3rem rgba(0, 0, 0, 0.3);
    z-index: 2222;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    background-color: rgba(var.$color-text-primary, 0.99);
    z-index: 999;
    transition: transform 0.4s ease-in;
  }

  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -555;
    opacity: 0;
    display: none;
  }

  &__links {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    & li {
      list-style: none;

      & a:link,
      a:visited {
        display: inline-block;
        font-size: 3rem;
        font-weight: 500;
        padding: 2rem 2rem;
        color: var.$color-primary;
        width: 100%;
        text-transform: uppercase;
        text-decoration: none;
        background-image: linear-gradient(
          120deg,
          transparent 0%,
          transparent 40%,
          rgba(153, 153, 153, 0.1) 50%,
          transparent 60%,
          transparent 100%
        );
        background-size: 280%;
        transition: all 0.5s;
      }

      & a:hover,
      a:active {
        background-position: 100%;
        transform: translateX(1rem);
      }
    }
  }

  &--open &__background {
    // transform: scale(80);
  }

  &--open &__nav {
    display: block;
    z-index: 1111;
    opacity: 1;
    animation: slideIn 0.4s ease-in;
    background-color: var.$color-background-tertiary;
  }

  &--open &__icon {
    background-color: transparent;
  }

  &--open &__icon::after {
    top: 0;
    transform: rotate(135deg);
  }

  &--open &__icon::before {
    top: 0;
    transform: rotate(-135deg);
  }
}

@media (max-width: 1270px) {
  .mobile-nav {
    display: block;
  }
}

@media (max-width: 560px) {
  .mobile-nav {
    left: 1rem;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
