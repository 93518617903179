@use "./../../variables/variables.scss" as var;

.category {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;

  &__full {
    grid-template-columns: repeat(2, 1fr);
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.6rem;
  flex-wrap: wrap;
  // background-color: var.$color-background-secondary;
  // padding: 0.6rem;
  // border-radius: 10px;
  // box-shadow: var.$box-shadow-light;

  &__title {
    font-size: 1.4rem;
  }

  &__view {
    display: flex;
    gap: 0.4rem;
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    margin-left: 0.3rem;
    padding: 0.3rem 0.4rem;
    border-radius: 0.6rem;
    width: 2.6rem;
    height: 2.6rem;

    &--active {
      background-color: #5f3d62;
    }
  }

  &__btn-icon {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.category__link {
}

.active {
  color: aquamarine;
}
.card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #fff;
  border-radius: 1rem;
}

.tags {
  display: flex;
  list-style: none;
}

.empty {
  margin-right: 2rem;
  font-size: 1.6rem;
}

.select {
  width: 10rem;
}

.spiner-container {
  position: relative;
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
}

@media (max-width: 1040px) {
  .category {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .category {
    grid-template-columns: repeat(2, 1fr);
    &__full {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 480px) {
  .category {
    grid-template-columns: 1fr;
  }
}
