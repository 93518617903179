//COLORS
$color-primary: #6526a0;
$color-secondary: #eacc48;
$color-tertiary: #c2005e;
$color-link: #7278f0;
$color-red: #ca0000;
$color-black: #000;
$color-white: #fff;
$color-yellow: #eacc48;
$color-violet: #7d6b9a;
$color-violet-dark: #8a2be2;
$color-violet-light: #e9d8f6;
$color-green: #2abead;
$color-gray: #bdbdbd;
$color-gray-light: #e6f2fb;
$color-gray-dark: #5c5c5c;
$color-violet-grey: #696976;
//background
$color-background-primary: #262c39;
$color-background-secondary: #1a1e27;
$color-background-tertiary: #201f2f;
$color-background-light: #fff;
$color-background-dark: #263237;
$color-background-gray: #f6f9ff;
$color-background-violet: #302838;
$color-background-gray-light: #fafafa;
$color-background-red: #9b1919;
$color-input-bg: #212b3a;
//text
$color-text-primary: #304750;
$color-text-light: #ffffff;
$color-text-orange: #ad6c12;
$color-text-yellow: #e4c800;
$color-text-dark: #2a1976;
$color-text-violet: #7d6b9a;
$color-text-red: #e54d4d;
$color-text-green: #49ab6b;
$color-text-white: #e8e8e8;
$color-text-gray: #acacac;
$color-text-gray-dark: #808080;

//FONTS
// $font-family-primary: "Raleway", sans-serif;
// $font-family-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
//   "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//   sans-serif;
// $font-family-primary: "Segoe UI", "Open Sans", "Roboto", "Inter", "Noto Sans",
//   "Nunito", "Titillium Web", "Oxygen", "Anek Devanagari", sans-serif;
$font-family-primary: "Nunito", sans-serif;
$font-family-secondary: "Nunito", sans-serif;

//SHADOWS
$drop-shadow: drop-shadow(2px 3px 5px rgba(22, 0, 0, 0.1));
$box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.4);
$box-shadow-light: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.25);
$box-shadow-dark: 0rem 0rem 1rem rgba(0, 0, 0, 0.9);
$box-shadow-inset: inset 0rem 0rem 0.5rem rgba(0, 0, 0, 0.3);

//BORDERS
$border-radius: 10px;

$clip-path-arrow-left: polygon(
  60% 0,
  80% 0,
  20% 50%,
  80% 100%,
  60% 100%,
  0 50%
);
$clip-path-arrow-right: polygon(
  20% 0,
  40% 0,
  100% 50%,
  40% 100%,
  20% 100%,
  80% 50%
);
$clip-path-arrow-up: polygon(
  0 60%,
  50% 20%,
  100% 60%,
  100% 80%,
  50% 40%,
  0 80%
);
$clip-path-arrow-down: polygon(
  0 20%,
  50% 60%,
  100% 20%,
  100% 40%,
  50% 80%,
  0 40%
);

@mixin custom-scroll($color: transparent) {
  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
    border-radius: 1rem;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    background: $color; /* color of the tracking area */
    // background: #443d6254; /* color of the tracking area */
    border-radius: 1rem;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ababab; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    opacity: 0;
    //   border: 3px solid #ababab; /* creates padding around scroll thumb */
    &:hover {
      background-color: #818181; /* color of the scroll thumb */
      opacity: 0;
    }
  }
}

@mixin linkA {
  display: inline-block;
  color: #7278f0;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: lighten(#7278f0, 7%);
  }
}

@mixin wrapper {
  width: 100%;
  max-width: 116rem;
  margin: 0 auto;
  padding: 0 2rem;
}

@mixin ellipsis($line: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin animation-show {
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin pulse {
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    // transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%);
    box-shadow: 0 0 15px #dfaf07;
    box-sizing: initial;
    z-index: -1;
    border: 5px solid #dfaf07;
    opacity: 0.8;
  }
  &::before {
    // border: 5px solid #dfaf07;

    box-shadow: 0 0 25px #dfaf07;
    animation: pulse2 2s ease-in-out infinite;
  }
  // &::after {

  //   // transform: translate(-50%, -50%) scale(1);
  //   // transform: translate(-50%, -50%);
  //   // opacity: 0.8;
  //   // animation: pulse2 3s 1s ease-in-out infinite;
  // }
  // &::after,
  // &::before {
  //   content: "";
  //   position: absolute;
  //   border: 2px solid #dfaf07;
  //   left: -20px;
  //   opacity: 0;
  //   right: -20px;
  //   top: -20px;
  //   bottom: -20px;
  //   border-radius: 10px;
  //   animation: pulse 2.5s linear infinite;
  // }

  // &::after {
  //   animation-delay: 1.25s;
  // }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes pulse2 {
  0%,
  100% {
    // transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    // transform: translate(-50%, -50%) scale(1.01, 1.03);
    opacity: 0.2;
  }
}
