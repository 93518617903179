@use "./../../../variables/variables.scss" as var;

.nav {
  display: flex;
  align-items: center;
  gap: 2rem;

  &__links {
    display: flex;
    gap: 2.4rem;
    list-style: none;
    text-transform: uppercase;
    align-items: center;

    & a:link,
    & a:visited {
      display: inline-block;
      text-decoration: none;
      color: var.$color-text-light;
      font-weight: 500;
      font-size: 1.4rem;
      transition: all 0.3s;
    }

    & a:hover,
    & a:active {
      color: var.$color-primary;
    }

    & a.active {
      color: var.$color-primary;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var.$color-primary;
    }
  }
}

@media (max-width: 1270px) {
  .nav {
    display: none;
  }
}
