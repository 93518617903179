@use "../../../../variables/variables.scss" as var;

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 50rem;
}

.img {
  width: 30rem;
  // height: 100%;
}

.images-container {
  position: relative;

  &--guide {
    @include var.pulse;
  }
}

.images {
  display: flex;
  gap: 1.4rem;
  flex-wrap: wrap;
}

.controls {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.version {
  transition: all 0.3s;
  border-radius: 0.5rem;
  padding: 0.4rem;
  font-size: 1.6rem;
  cursor: pointer;

  &--active {
    border: 1px solid blueviolet;
    background-color: rgb(34, 34, 34);
  }
  &--downloaded {
    color: blueviolet;
  }
}

.versions {
  display: flex;
  gap: 2rem;
  list-style: none;
  flex-wrap: wrap;
  // overflow: hidden;
  transition: all 0.3s ease;

  &__list {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    list-style: none;
  }
}

.panel {
  display: flex;
  justify-content: space-between;
}

.btn-back {
  padding: 0.4rem 2rem;
  border: none;
  border-radius: 1rem;
  // position: absolute;
  // top: 1rem;
  // left: 1rem;
}

.btn-edit {
  padding: 0.4rem 2rem;
  border: none;
  border-radius: 1rem;
  // position: absolute;
  // top: 1rem;
  // right: 1rem;
}

.btn-examples {
  font-size: 2.6rem;
  cursor: pointer;
  background-color: rgb(82, 87, 87);
  border-radius: 1rem;
  padding: 0.2rem 0.6rem;
  &--active {
    color: blueviolet;
  }
}

.mode-switch {
  display: flex;
  border-radius: var.$border-radius;
  border: 1px solid rgba(255, 255, 255, 0);
}

.btn-mode {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 8rem;
  font-size: 1.8rem;
  // border: 1px solid rgba(255, 255, 255, 0.3);
  border: 1px solid var.$color-gray-dark;
  // text-transform: uppercase;
  background-color: transparent;
  // background-color: #fff;
  padding: 0.4rem 0.6rem;
  // border: none;
  transition: all 0.3s;
  color: #fff;
  cursor: pointer;

  &:hover {
    // border: 1px solid rgba(255, 255, 255, 0.5);
    border: 1px solid var.$color-text-gray;
  }

  &--active {
    background-color: blueviolet;
  }

  &--left {
    border-radius: 10px 0 0 10px;
  }
  &--right {
    border-radius: 0 10px 10px 0;
  }
}

.select {
  border-radius: 1rem;
  padding: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: var.$color-text-white;
  background-color: var.$color-background-tertiary;

  &:hover {
    // background-color: aqua;
  }
}

.sort {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.button-add {
  height: 3.4rem;
  // margin-left: auto;
}

.notification {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.4rem;
  // margin-bottom: 3rem;
  background-color: var.$color-background-tertiary;
  border-radius: 10px;
  padding: 1rem 0.9rem;
  color: var.$color-text-gray;

  &__svg {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    color: var.$color-text-gray;
  }
}

.svg {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  // transform: translateY(3px);
  margin-bottom: -5px;
}

// .form-container {
//   overflow-y: scroll;
//   max-height: 80vh;
// }

@media (max-width: 600px) {
  .button-add {
    // margin-left: initial;
  }
  .sort {
    margin-left: initial;
  }

  .notification {
    // flex-direction: column;
  }
}
@media (max-width: 480px) {
  .btn-mode {
    width: 6rem;
  }
}
