@use "../../../variables/variables.scss" as var;

.ref-images {
  padding: 0.4rem;
  display: flex;
  gap: 1rem;
  // justify-content: space-between;
  background-color: rgba(darken(var.$color-background-secondary, 5%), 0.7);
  background-color: var.$color-background-secondary;
  border-radius: 10px;
  width: 100%;
  list-style: none;
  // box-shadow: var.$box-shadow-light;

  &__item {
    position: relative;
    width: 8rem;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    // border: 1px solid transparent;

    &:hover {
      // border: 1px solid var.$color-gray;
    }

    &--def {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8rem;
      border-radius: 10px;
      overflow: hidden;
      background-color: rgba(117, 107, 118, 0.2);

      & svg {
        width: 2.6rem;
        opacity: 0.3;
      }
    }
  }

  &__image {
    transition: all 0.3s;
    &:hover img {
      transform: scale(1.05);
    }
  }

  &__nsfw {
    filter: blur(20px);
  }
}

.close {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #5966a1;
  }

  & svg {
    width: 2rem;
  }
}
