@use "../../variables/variables.scss" as var;

.category {
  display: flex;
  gap: 1.6rem;
  margin-left: 1.8rem;
  justify-content: space-between;
  align-items: flex-start;

  &__edit {
    display: flex;
    align-items: flex-end;
    gap: 0.6rem;
    border-radius: 7px;

    & svg {
      width: 1.6rem;
    }
  }
}

.category__list {
  margin-left: 1.8rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.category__link {
  position: relative;
  // margin-bottom: 1rem;
  border-bottom: 1px solid transparent;
  // border-radius: 10px;
  // padding: 0.2rem 0.4rem;
  cursor: pointer;
  transition: all 0.3s;

  &:not(.active):hover {
    color: lighten(var.$color-primary, 20%);
  }

  &--all {
    // padding-right: 1rem;
    // border-right: 1px solid var.$color-text-gray-dark;
    // border: 1px solid var.$color-text-gray-dark;
    border: 1px solid var.$color-violet-grey;
    border-radius: 10px;
    padding: 0.2rem 1.3rem;
    font-size: 1.6rem;
    transition: all 0.3s;

    &:not(.active):hover {
      border: 1px solid lighten(var.$color-primary, 20%);
    }

    &.active {
      border-color: #7fffd4;
    }
  }
}

.active {
  color: #7fffd4;
}

@media (max-width: 480px) {
  .category {
    margin-left: 0.2rem;
    gap: 0.8rem;
  }
}
