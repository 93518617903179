@use "./../../../../variables/variables.scss" as var;
@include var.animation-show;

.guide {
  &__content {
    &--0 {
      bottom: -30rem;
      left: -28rem;
    }

    &--1 {
      bottom: -5.2rem;
      right: 8rem;
    }
  }
}

.btn-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;

  & svg {
    width: 1.5rem;
  }
}

.svg {
  width: 1.5rem;
  height: 1.5rem;

  &--medium {
    display: inline-block;
    margin-bottom: -0.3rem;
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 1160px) {
  .guide__content--2 {
    // top: 7.6rem;
  }
}

@media (max-width: 560px) {
  .guide__content--0 {
    left: -12rem;
  }
}
