@use "../../../variables/variables.scss" as var;

.wrapper {
  display: flex;
  position: relative;
  // overflow: hidden;
}

// .wrap {
//   @include var.wrapper;
//   background-color: rgb(var.$color-background-primary, 0.9);
// }

.menu-container {
  position: relative;
  background-color: var.$color-background-secondary;
  box-shadow: var.$box-shadow;
  width: 100%;
  z-index: 20;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 2rem;
  height: 5rem;
  // padding: 2rem;
  width: 100%;
}

.logo {
  display: block;
  width: 12rem;
  flex-shrink: 0;

  & img {
    width: 100%;
  }
}

.content {
  display: grid;
  position: relative;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  min-height: 100vh;
  // max-width: 120rem;
  // margin: 0 auto;
  //   padding: 0 2rem;
  //   background-color: #282c34;
}

.mode-switch {
  font-size: 1.4rem;
  border-radius: var.$border-radius;
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  flex-shrink: 0;
}
.btn-mode {
  width: 5rem;
  font-size: 1rem;
  background-color: transparent;
  // background-color: #fff;
  padding: 0.6rem 0.8rem;
  border: none;
  transition: all 0.3s;
  color: #fff;
  cursor: pointer;

  &--active {
    background-color: var.$color-primary;
  }
}

.btn-search {
  display: none;
  & svg {
    width: 2rem;
  }
}

.btn-auth {
  margin-left: auto;
}

.link {
  @include var.linkA;
}

// .active-corousel {
//   position: sticky;
//   top: 10rem;
//   z-index: 14;
//   // left: 50%;
//   // transform: translateX(50%);
// }

@media (max-width: 1270px) {
  .menu {
    padding-left: 5rem;
  }
}

@media (max-width: 680px) {
  .wrap {
    position: relative;
    z-index: 40;
  }
  .logo {
    margin-right: auto;
    // display: none;
  }
  .mode-switch {
    // display: none;
  }
  .btn-search {
    display: block;
  }
  .search-hidden {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .wrap {
    padding: 0 2.8rem 0 1rem;
  }
}

@media (max-width: 540px) {
  .logo {
    display: none;
  }
  .menu {
    gap: 1rem;
  }
}
