@use "./../../../../variables/variables.scss" as var;
@include var.animation-show;

.guide__content {
  &--0 {
    top: 50%;
    left: -25rem;
    width: 24rem;
    max-width: 25rem;
    transform: translateY(-50%);
  }
}

.btn-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid var.$color-text-white;
  border-radius: 6px;
  color: var.$color-text-white;
  // color: #e4c800;
  transform: translateY(1px);
  transform: translateY(2px);

  & svg {
    width: 1.5rem;
  }
}

@media (max-width: 740px) {
  .guide__content--0 {
    left: 0.4rem;
  }
}
