@use "../../variables/variables.scss" as var;
@include var.animation-show;

.wrap {
  // background-color: #282c34;
  display: flex;
  flex-direction: column;
  height: 100%;
  animation: show 0.6s ease-in forwards;
  /* align-items: center; */
  /* justify-content: center; */
  // font-size: calc(10px + 2vmin);
  // color: white;
  // padding: 0 2rem 0 2rem;
}

.active {
  color: blueviolet;
}

.outlet {
  width: 100%;
}

.config {
  display: flex;
  justify-content: space-between;
  // margin-top: 2.8rem;
  gap: 1.6rem;
  height: 100%;
  // flex-grow: 1;

  & > div:first-child {
    width: 100%;
  }
}
