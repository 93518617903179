@use "./../../variables/variables.scss" as var;

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  // top: 100%;
  // left: 100%;
  z-index: 30;
  // transform: translateX(-100%);

  &__container {
    // min-height: 2rem;
    // max-height: 40rem;
    width: 97%;
    // padding: 0.4rem 0.6rem;
    border-radius: 10px 0 10px 10px;
    background-color: var.$color-background-primary;
    box-shadow: var.$box-shadow;
  }

  &__settings {
    padding: 0.3rem 0.6rem;
    border-radius: 10px 0 0 0;
    background-color: darken(var.$color-background-primary, 4%);
    box-shadow: var.$box-shadow;
  }

  &__content {
    padding: 0.4rem 0 0.6rem 0.4rem;
    min-height: 2rem;
    max-height: 30rem;
    list-style: none;
    overflow-y: scroll;
    @include var.custom-scroll;
  }
}

.btn-close {
  display: block;
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;

  &__cross {
    display: block;
    margin-left: auto;
    width: 1.4rem;
    height: 1.4rem;
    background-color: rgb(168, 168, 168);
    clip-path: polygon(
      10% 0,
      0 10%,
      40% 50%,
      0 90%,
      10% 100%,
      50% 60%,
      90% 100%,
      100% 90%,
      60% 50%,
      100% 10%,
      90% 0,
      50% 40%
    );
  }
}

// &__categories {
//   margin-bottom: 1rem;
//   background-color: var.$color-background-tertiary;
//   border-radius: 1rem;
//   &-item {
//     padding: 0.4rem 0.6rem;
//   }
// }

// &__model {
// }

// &__item {
//   display: flex;
//   position: relative;
//   align-items: center;
//   justify-content: space-between;
//   gap: 0.4rem;
//   background-color: var.$color-background-tertiary;
//   border-radius: 1rem;
//   // overflow: hidden;
//   margin-bottom: 0.6rem;
//   margin-right: 1rem;

//   :hover {
//     background-color: lighten(var.$color-background-tertiary, 5%);
//   }
// }

// &__add {
//   position: absolute;
//   right: 0;
//   transform: translateX(20%);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   justify-self: flex-end;
//   font-size: 2.8rem;
//   border-radius: 1rem;
//   background-color: rgb(var.$color-background-tertiary, 0.8);
//   border: 1px solid rgba(255, 255, 255, 0.3);
//   width: 3.6rem;
//   height: 3.6rem;
//   line-height: 1;
//   font-family: serif;
//   cursor: pointer;
//   transition: all 0.3s;
//   flex-shrink: 0;

//   &:hover {
//     background-color: var.$color-primary;
//   }
// }

// &__type {
//   text-transform: capitalize;
//   border-right: 2px solid rgba(255, 255, 255, 0.3);
//   padding-right: 0.5rem;
// }

// &__link {
//   display: grid;
//   grid-template-columns: 8rem 1fr;
//   gap: 1rem;
//   width: 100%;
//   align-items: center;
//   text-decoration: none;
// }

// &__text-link {
//   color: var.$color-link;
//   text-decoration: none;

//   &:hover {
//     color: lighten(var.$color-link, 5%);
//   }
// }

// &__name {
//   font-size: 1.6rem;
//   color: var.$color-text-white;
// }
// }

// .img-container {
//   //   width: 8rem;
//   border-radius: 1rem 0 0 1rem;
//   //   overflow: hidden;
//   //   flex-shrink: 0;
// }
// .error {
//   text-align: center;
//   margin-bottom: 1rem;
// }

// .spiner-container {
//   position: relative;
//   width: 100%;
//   height: 2rem;
// }

// @media (max-width: 600px) {
//   .search__dropdown {
//     transform: translateX(-30%);
//   }
// }
