@use "./../../../../variables/variables.scss" as var;
@include var.animation-show;

.guide__content {
  &--0 {
    top: 5.4rem;
    right: 0;
  }
}

@media (max-width: 1160px) {
  .guide__content--2 {
    // top: 7.6rem;
  }
}

@media (max-width: 600px) {
  // .guide__content--1 {
  //   left: 1rem;
  // }

  // .guide__content--1 .guide__arrow-bg {
  //   top: -1rem;
  //   left: 6rem;
  // }

  .guide__content--2 {
    left: 3rem;
  }

  .guide__content--2 .guide__arrow-bg {
    top: -1rem;
    left: 19.1rem;
  }
}
