.field-category {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 25rem;
  flex: 1 1;
}

.field-title {
  font-size: 1.6rem;
  border-bottom: 1px solid #aaaaaa;
  padding-bottom: 0.6rem;
}
