@use "./../../variables/variables.scss" as var;

.search {
  position: relative;
  width: 100%;
  max-width: 35rem;

  &__field {
    max-width: 35rem;
    display: flex;
    align-items: center;
  }

  &__input {
    border-radius: 1rem 0 0 1rem;
    padding: 0.5rem;
    width: 100%;
    height: 2.8rem;
    padding-left: 1rem;
    background-color: var.$color-input-bg;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: var.$box-shadow-inset;
    color: var.$color-text-white;

    &:focus-visible {
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }

    &:active,
    &:focus,
    &:visited,
    &:target,
    &:focus-within,
    &:focus-visible {
      background: #323148;
    }

    &[type="search"] {
      -webkit-appearance: textfield;
    }

    &[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  &__btn {
    border-radius: 0 1rem 1rem 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #323148;
    width: 3.2rem;
    height: 2.8rem;
    padding: 0.4rem 0.6rem;
    color: var.$color-text-white;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: lighten(#323148, 5%);
      // background-color: #5f3d62;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }

    &:active {
      border: 1px solid rgba(255, 255, 255, 0.3);
      background-color: darken(#323148, 5%);
      box-shadow: var.$box-shadow-inset;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0.4rem;
    // min-height: 2rem;
    // max-height: 40rem;
    width: 97%;
    // padding: 0.4rem 0.6rem;
    border-radius: 0 0 1rem 1rem;
    background-color: var.$color-background-primary;
    box-shadow: var.$box-shadow;
    // transform: translateX(-50%);
    z-index: 60;
  }

  &__settings {
    padding: 0.3rem 0.6rem;
    background-color: darken(var.$color-background-primary, 4%);
    // background-color: darken(var.$color-background-primary, 5%);
  }

  &__btn-close {
    display: block;
    margin-left: auto;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__cross {
    display: block;
    margin-left: auto;
    width: 1.4rem;
    height: 1.4rem;
    background-color: rgb(168, 168, 168);
    clip-path: polygon(
      10% 0,
      0 10%,
      40% 50%,
      0 90%,
      10% 100%,
      50% 60%,
      90% 100%,
      100% 90%,
      60% 50%,
      100% 10%,
      90% 0,
      50% 40%
    );
  }

  &__result {
    // position: absolute;
    // top: 105%;
    // left: 50%;

    // width: 97%;
    // padding: 0.4rem 0.6rem;
    // border-radius: 0 0 1rem 1rem;
    // background-color: var.$color-background-primary;
    // box-shadow: var.$box-shadow;
    // transform: translateX(-50%);
    padding: 0.4rem 0 0.6rem 0.4rem;
    min-height: 2rem;
    max-height: 30rem;
    list-style: none;
    overflow-y: scroll;
    @include var.custom-scroll;
  }

  &__categories {
    margin-bottom: 1rem;
    background-color: var.$color-background-tertiary;
    border-radius: 1rem;
    &-item {
      padding: 0.4rem 0.6rem;
    }
  }

  &__model {
  }

  &__item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    gap: 0.4rem;
    background-color: var.$color-background-tertiary;
    border-radius: 1rem;
    // overflow: hidden;
    margin-bottom: 0.6rem;
    margin-right: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: lighten(var.$color-background-tertiary, 5%);
    }
  }

  &__add {
    position: absolute;
    right: 0;
    transform: translateX(20%);
  }

  &__type {
    text-transform: capitalize;
    border-right: 2px solid rgba(255, 255, 255, 0.3);
    padding-right: 0.5rem;
  }

  &__link {
    display: grid;
    grid-template-columns: 8rem 1fr;
    gap: 1rem;
    width: 100%;
    align-items: center;
    text-decoration: none;
  }

  &__text-link {
    color: var.$color-link;
    text-decoration: none;

    &:hover {
      color: lighten(var.$color-link, 5%);
    }
  }

  &__name {
    display: -webkit-box;
    font-size: 1.6rem;
    margin-right: 10%;
    color: var.$color-text-white;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 0.6rem;
    word-break: break-all;
    @include var.ellipsis;
  }
}

.img-container {
  //   width: 8rem;
  border-radius: 1rem 0 0 1rem;
  //   overflow: hidden;
  //   flex-shrink: 0;
}
.error {
  text-align: center;
  margin: 1rem 0;
  // margin-bottom: 1rem;
}

.spiner-container {
  position: relative;
  width: 100%;
  height: 2rem;
}

.btn-more {
  display: block;
  margin: auto;
}

.type {
  // position: absolute;
  // left: 2px;
  // top: 2px;
  font-size: 1rem;
  color: var.$color-text-white;
  // background-color: rgba(137, 43, 226, 0.7);
  background-color: rgba(var.$color-primary, 0.8);
  padding: 0.4rem 0.8rem;
  border-radius: 1rem;
  text-transform: uppercase;
  align-self: self-start;
  // width: fit-content;
  //   font-size: 1.6rem;
  //   z-index: 2;
}

.models {
  font-size: 1rem;
  color: #acacac;
  background-color: #323148;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  margin-left: 0.6rem;
}

.card__content {
  padding: 0.4rem 0.6rem;
}

@media (max-width: 820px) {
  .search__dropdown {
    position: fixed;
    width: 95vw;
    position: fixed;
    top: 5rem;
  }

  .search__result {
    max-height: 50vh;
  }
}

@media (max-width: 680px) {
  .search {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5rem;
    left: 0;
    z-index: 30;
    background-color: var.$color-background-primary;
    max-width: 100%;
    height: 5rem;
    padding-top: 3.6rem;
  }
  .search__dropdown {
    top: 9rem;
  }

  .search__field {
    width: 90%;
    max-width: 90%;
  }
}
