@use "./../../../variables/variables.scss" as var;

.category__link {
  position: relative;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;

  &--border {
    border-left: 1px solid #9e9e9e;
    padding: 0.2rem 0.8rem;
    margin: 0.6rem 0;
  }

  &:not(.active):hover {
    // border-bottom: 1px solid var.$color-primary;
    // color: #b261f5;
    color: lighten(var.$color-primary, 20%);
  }

  &--all {
    // padding-right: 2rem;
    // border-right: 1px solid var.$color-text-gray-dark;
    border: 1px solid var.$color-violet-grey;
    border-radius: 10px;
    padding: 0.2rem 1.3rem;
    font-size: 1.6rem;
    transition: all 0.3s ease;

    &:not(.active):hover {
      border: 1px solid lighten(var.$color-primary, 20%);
    }

    &.active {
      border-color: var.$color-primary;
    }
  }
}

.active {
  color: #8a2be2;
}
