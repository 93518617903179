@use "./../../variables/variables.scss" as var;

.example {
  // position: absolute;
  // left: 0;
  // top: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  // width: 100%;
  // height: 100%;

  border-radius: 1rem;
  // padding: 2.8rem 0.2rem 2.8rem 1.2rem;
  // overflow: hidden;
  // z-index: 1111;
  // transform: translateY(-100%);

  &__info {
    display: flex;
    // grid-template-columns: 30rem 1fr auto;
    // grid-template-columns: auto;
    gap: 2rem;
    font-size: 1.4rem;
    overflow-y: scroll;
    // height: 80vh;
    height: 100%;
    align-items: flex-start;
    @include var.custom-scroll(transparent);
  }

  &__info-item {
    width: 21.6rem;
    word-break: break-all;
  }

  &__info-name {
    color: var.$color-text-gray;
    margin-right: 0.6rem;
  }

  &__img {
    // width: 50rem;
    height: 100%;

    & img {
      max-width: 100%;
    }
  }

  &__prompt {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    min-width: 42rem;

    margin: 0.5rem;
    padding: 0.5rem;

    &--guide {
      @include var.pulse;
    }
  }

  &__config {
    width: 22rem;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 0.6rem;
    margin-top: 2.8rem;
    padding-right: 1rem;
  }

  &__config-block {
    width: 100%;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 0.6rem;
    margin-top: 2.8rem;
    padding-right: 1rem;
  }

  &__resourses {
    position: relative;
    list-style: none;

    &--guide {
      @include var.pulse;
    }
  }
}

.config__name {
  word-break: break-word;
}

.seed {
  display: inline-flex;
  position: relative;
  align-items: center;
  gap: 0.6rem;
  // font-size: 1.8rem;
  padding: 0 0.4rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  cursor: pointer;
  background-color: #323148;
  margin-left: 0.8rem;
  transition: all 0.3s;

  &--copied {
    color: #49ab6b;
    border-color: #49ab6b;
    &:hover {
      color: #49ab6b;
      border-color: #49ab6b;
    }
  }

  &__copied {
    position: absolute;
    background-color: darken(#323148, 6);
    padding: 0 0.8rem;
    border-radius: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & svg {
    width: 1.6rem;
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: darken(#323148, 2.8);
  }

  &:active {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #48313f;
    box-shadow: var.$box-shadow-inset;
  }
}

// .btn-copy {
//   color: #b2b2b2;
//   padding: 0.2rem 0.5rem;
//   margin-left: 0.4rem;
//   background: none;
//   border: 1px solid #b2b2b2;
//   border-radius: 0.5rem;
//   cursor: pointer;
//   transition: all 0.1s;

//   &:hover {
//     color: #7278f0;
//     border-color: #7278f0;
//   }

//   &:active {
//     color: var.$color-text-green;
//     border-color: var.$color-text-green;
//     transform: translateY(1px);
//   }
// }

.btn {
  // &__close {
  //   position: absolute;
  //   cursor: pointer;
  //   top: 0.4rem;
  //   right: 1rem;
  //   color: #a8a8a8;
  //   transition: all 0.3s;

  //   & svg {
  //     width: 2.6rem;
  //   }

  //   &:hover {
  //     color: lighten(#a8a8a8, 20%);
  //   }
  // }

  &__cross {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(168, 168, 168);
    clip-path: polygon(
      10% 0,
      0 10%,
      40% 50%,
      0 90%,
      10% 100%,
      50% 60%,
      90% 100%,
      100% 90%,
      60% 50%,
      100% 10%,
      90% 0,
      50% 40%
    );
  }
}

.tags__list ul {
  padding: 1.4rem;
  border-radius: 1rem;
  background-color: rgb(var.$color-background-tertiary, 0.85);
  box-shadow: var.$box-shadow-inset;
}

.resource {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(213, 155, 155, 0.3);
  border-radius: 1rem;
  padding: 0.4rem 0.8rem;
  margin-top: 1rem;
  background-color: rgb(var.$color-background-tertiary, 0.85);
  box-shadow: var.$box-shadow;
  width: 20rem;

  &__link {
    font-size: 1.6rem;
    @include var.linkA;
  }

  &__name {
    display: -webkit-box;
    // cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 1.6rem;
    margin-bottom: 0.4rem;
    word-break: break-word;
  }

  &__source {
    display: inline-flex;
    align-items: center;
    gap: 0.6rem;
    // margin-top: 0.2rem;

    & svg {
      width: 1.6rem;
    }
    // border-top: 1px solid #fff;
  }

  &__checkpoint-lk {
    display: flex;
    gap: 0.2rem;
  }

  &__info {
    display: flex;
    gap: 1rem;
  }

  &__type {
    color: var.$color-text-green;
  }

  &__version {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    color: var.$color-text-gray;

    &-svg {
      width: 1.6rem;
      color: var.$color-text-yellow;
      flex-shrink: 0;

      &--saved {
        color: var.$color-text-green;
      }
    }
  }

  &__version-name {
    word-break: break-word;
    @include var.ellipsis(1) // display: -webkit-box;
      // word-break: break-all;
      // color: var.$color-text-gray;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
;
  }

  &__add {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(20%, -50%);
  }

  &__field {
    // margin-top: 0.2rem;
  }
}

.link {
  display: inline-block;
  color: #7278f0;
  transition: all 0.3s;

  &:hover {
    color: darken(#7278f0, 1);
  }
}

.spiner-container {
  position: relative;
  height: 3rem;
  width: 3rem;
  margin: 0 auto;
  margin-top: 1rem;
}
.plus {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgb(168, 168, 168);
  clip-path: polygon(
    10% 0,
    0 10%,
    40% 50%,
    0 90%,
    10% 100%,
    50% 60%,
    90% 100%,
    100% 90%,
    60% 50%,
    100% 10%,
    90% 0,
    50% 40%
  );
  transform: rotate(45deg);
}

// @media (min-width: 1310px) {
//   .example__info {
//     overflow-y: scroll;
//   }
// }

@media (max-width: 1310px) {
  .example__info {
    overflow: visible;
  }
}

@media (max-width: 740px) {
  .example__info {
    flex-wrap: wrap;
    overflow: visible;
  }

  .example__config {
    width: 100%;
  }

  .example__resourses {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
}

@media (max-width: 510px) {
  .example__config-block {
    font-size: 1.6rem;
  }
  .example__info-item {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .example__prompt {
    min-width: 28rem;
  }
}

@media (max-width: 480px) {
  .resource {
    font-size: 1.6rem;
    width: 100%;
  }
}
